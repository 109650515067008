import React from 'react';
import { IconButton, Button, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_CERT_TEMPLATE } from '../../constants/gqls/certificates';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteCertTemplateBtn({certTypeId}) {
  const [open, setOpen] = React.useState(false);
  const [deleteCertTemplate, { loading }] = useMutation(DELETE_CERT_TEMPLATE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteCertTemplate({ variables: {
      certTypeId
    }}).then(res=>{
      console.log(res);
      setOpen(false);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Delete Template">
        <IconButton onClick={handleOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this PDF template, may affect certificate generation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
