import { gql } from '@apollo/client';


export const FILE_UPLOAD = gql`
  mutation UploadFile($systemFileType: SystemFileType!, $relatedId: ID!) {
    uploadFile(systemFileType: $systemFileType, relatedId: $relatedId)
  }
`;

export const DOWNLOAD_TOKEN_CERT_TEMPLATE = gql`
  query CertTemplateDownloadToken($id: ID!) {
    certTemplateDownloadToken(id: $id)
  }
`;
