import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useReactiveVar } from "@apollo/client";
import FunctionBtn from './FunctionBtn';
import Title from './Title';
import Description from './Description';
import Item from './Item';
import SubmitBtn from './SubmitBtn';
import ItemTextEdit from '../Items/ItemTextEdit';
import { formInputEnabled, formSubmitted } from '../../constants/cache';
import { UPDATE_DOCUMENT, DOCUMENT } from '../../constants/gqls/documents';
import { CREATE_ITEM } from '../../constants/gqls/items';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    maxWidth: '816px',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      minWidth: '816px',
    },
  },
  inputRow: {
    paddingTop: theme.spacing(2),
    position: 'relative',
  },
  focusBar: {
    position: 'absolute',
    top: 0,
    left: `-${theme.spacing(2)}px`,
    width: '3px',
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DocPaper({ doc, mode }) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const submitted = useReactiveVar(formSubmitted);
  const [focus, setFocus] = React.useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = React.useState(false);
  const [items, setItems] = React.useState([]);
  // const [filledOK, setFilledOK] = React.useState(true);
  const [updateDocument, { loading }] = useMutation(UPDATE_DOCUMENT);
  const [createItem, { loading: itemLoading }] = useMutation(CREATE_ITEM, {
    refetchQueries: [DOCUMENT],
  });
  // console.log(doc);

  formInputEnabled(doc.inputEnabled);

  React.useEffect(() => {
    let mode = searchParams.get("mode");
    let submission = searchParams.get("submission");
    if ((mode !== 'edit' && mode !== 'clean') && !submission && doc.myLastSub) {
      setSearchParams({ submission: doc.myLastSub }, { replace: true });
    }
  }, [searchParams, doc.myLastSub, setSearchParams]);

  React.useEffect(() => {
    setShowSubmitBtn(Boolean(doc.isForm && doc.inputEnabled && doc.canSubmit &&
      (!submitted) && (mode === 'view' || !doc.editable)))
  }, [doc, mode, submitted]);

  React.useEffect(() => {
    let itemlist = [...doc.itemsConnection.edges];
    itemlist.sort((a, b) => a.index - b.index);
    setItems(itemlist);
  }, [doc.itemsConnection]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = (update) => {
    return updateDocument({
      variables: {
        docId: doc._id,
        update
      }
    });
  }

  const addItem = (text) => {
    return createItem({
      variables: {
        text,
        docId: doc._id,
        index: doc.itemsConnection.totalCount
      }
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Paper square className={classes.paper}>
      <Title text={doc.title}
        update={(doc.editable && mode !== 'view') ? update : null} loading={loading} />
      <Description text={doc.description}
        update={(doc.editable && mode !== 'view') ? update : null} loading={loading} />
      {items.map(item =>
        <Item key={item.node._id} 
          item={item.node} docIsProfileForm={doc.isProfileForm}
          index={item.index} count={items.length}
          isFocus={searchParams.get("item") === item.node._id} mode={mode} />
      )}
      {doc.editable && mode !== 'view' &&
        <div className={classes.inputRow}>
          <ItemTextEdit addItem={addItem} loading={itemLoading} setFocus={setFocus} />
          {focus &&
            <div className={classes.focusBar} />
          }
        </div>
      }
      { !doc.isProfileForm &&
        <SubmitBtn submitPrompt={doc.submitPrompt} showSubmitBtn={showSubmitBtn} />
      }
      <FunctionBtn doc={doc} />
    </Paper>
  );
}
