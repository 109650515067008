import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { formFilledOK } from '../../constants/cache';
import { SUBMIT } from '../../constants/gqls/forms';
import { DOCUMENT_ISFORM } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderTop: '1px dashed '+theme.palette.secondary.main,
    '& button': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SubmitBtn({submitPrompt, showSubmitBtn}) {
  const classes = useStyles();
  const filledOK = useReactiveVar(formFilledOK);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [submit, { loading }] = useMutation(SUBMIT, {
    refetchQueries: [ DOCUMENT_ISFORM ]
  });
  // console.log(submitPrompt);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSubmit = () => {
    // setOpen(true);
    submit({ variables: {
      subId: searchParams.get("submission")
    }}).then(res=>{
      // console.log(res);
      setOpen(true);
    }, err=>{
      // console.log(err.message);
      setError(err.message);
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { showSubmitBtn &&
        <div className={classes.root}>
          <LoadingButton variant="contained" disabled={!filledOK}
            onClick={handleSubmit} loading={loading}
          >
            Submit
          </LoadingButton>
        </div>
      }
      { submitPrompt && submitPrompt.promptTemplate &&
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Submitted</DialogTitle>
          <DialogContent>
            {submitPrompt.promptTemplate.split('\n').map((p, i)=>(
              <DialogContentText key={i}>{p}</DialogContentText>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Dialog
        open={Boolean(error)}
        onClose={handleErrorClose}
      >
        <DialogTitle>Submit error</DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
