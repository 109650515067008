import React from 'react';
import { useMutation } from "@apollo/client";
import { IconButton, Button, Tooltip, Dialog, DialogTitle, TextField, DialogContent, DialogActions } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { CREATE_DATA_TABLE, DATA_TABLES } from '../../constants/gqls/dataAnalysis';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: '600px',
      maxWidth: '800px',
    },
    '& .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root' : {
      paddingTop: theme.spacing(2)
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ImportBtn() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [dataTable, setDataTable] = React.useState(null);
  const [createDataTable, { loading }] = useMutation(CREATE_DATA_TABLE, {
    refetchQueries: [ DATA_TABLES ],
  });
  console.log(dataTable);

  React.useEffect(() => {
    if (value) {
      try {
        setDataTable(JSON.parse(value));
      } catch (error) {
        setDataTable(null);
      }
    }
  }, [value]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onValueChange = (event) => {
    setValue(event.target.value);
  }

  const onClick = () => {
    let columnCreate = dataTable.dataTableColumns.map(item=>({
      node: {
        name: item.name,
        field: item.field,
        code: item.code
      }
    }));

    createDataTable({ variables: {
      input: [{
        name: dataTable.name,
        queryType: dataTable.queryType,
        whereType: dataTable.whereType,
        optionsType: dataTable.optionsType,
        where: dataTable.where,
        options: dataTable.options,
        queryFields: dataTable.queryFields,
        dataTableColumns: { create: columnCreate }
      }]
    }}).then(res=>{
      handleClose();
    });
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Tooltip title="Import table">
        <IconButton onClick={handleOpen}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>
          Import table
        </DialogTitle>
        <DialogContent>
          <TextField id="table-export" label="JSON"
            multiline fullWidth rows={24}
            value={value} onChange={onValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading || !dataTable}
            onClick={onClick}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
