import { gql } from '@apollo/client';
import { dataDetails, valueSetDetails, personSum } from './fragments';


export const PROFILE_KEYS = gql`
  query ProfileKeys($where: ProfileKeyWhere, $options: ProfileKeyOptions) {
    profileKeys(where: $where, options: $options) {
      _id key valueFields { _id index name toStr }
    }
  }
`;

export const VALUE_FIELDS = gql`
  query ValueFields {
    valueFields {
      name
    }
  }
`;

export const VALUES = gql`
  query Values($where: ValueWhere, $options: ValueOptions) {
    values(where: $where, options: $options) {
      _id text max min bool
    }
    valuesCount(where: $where)
  }
`;

export const VALUE_SETS = gql`
  query ValueSets($where: ValueSetWhere) {
    valueSets(where: $where) {
      _id values { text field { _id name }}
      owners { _id name email isUGC }
    }
  }
`;

export const DATA = gql`
  query Data($where: DataWhere) {
    data(where: $where) {
      _id value values boolValue options { _id text }
      ownerPerson { _id name email isUGC }
      profileKey { _id key }
    }
  }
`;

export const CREATE_VALUE_FIELD = gql`
  mutation CreateValueField($inputId: ID!) {
    createValueField(inputId: $inputId) {
      _id valueFields {
        _id index name multiline required
      }
    }
  }
`;

export const DELETE_VALUE_FIELD = gql`
  mutation DeleteValueField($valueFieldId: ID!) {
    deleteValueField(valueFieldId: $valueFieldId) {
      _id valueFields { _id index }
    }
  }
`;

export const UPDATE_VALUE_FIELD = gql`
  mutation UpdateValueFields($where: ValueFieldWhere, $update: ValueFieldUpdateInput) {
    updateValueFields(where: $where, update: $update) {
      valueFields {
        _id name multiline required toStr
      }
    }
  }
`;

export const SORT_VALUE_KEYS = gql`
  mutation SortValueFields($valueFieldId: ID!, $index: Int!) {
    sortValueFields(valueFieldId: $valueFieldId, index: $index) {
      inputs {
        _id valueFields { _id index }
      }
    }
  }
`;

export const CREATE_VALUE_SET = gql`
  mutation CreateValueSet($itemId: ID!, $dataId: ID, $submissionId: ID, $profileId: ID) {
    createValueSet(itemId: $itemId, dataId: $dataId, submissionId: $submissionId, profileId: $profileId) {
      ${dataDetails} submission {
        _id submitted
      }
    }
  }
`;

export const DELETE_VALUE_SET = gql`
  mutation DeleteValueSet($dataId: ID!, $valueSetId: ID!) {
    deleteValueSet(dataId: $ dataId, valueSetId: $valueSetId) {
      ${dataDetails}
    }
  }
`;

export const SORT_VALUE_SET = gql`
  mutation SortValueSets($dataId: ID!, $valueSetId: ID, $index: Int, $by: ID, $asc: Boolean) {
    sortValueSets(dataId: $ dataId, valueSetId: $valueSetId, index: $index, by: $by, asc: $asc) {
      ${dataDetails}
    }
  }
`;

export const UPDATE_VALUE = gql`
  mutation UpdateValue($valueSetId: ID!, $valueFieldId: ID!, $value: String!) {
    updateValue(valueSetId: $valueSetId, valueFieldId: $valueFieldId, value: $value) {
      ${valueSetDetails}
    }
  }
`;

export const SHARE_VALUE_SET = gql`
  mutation ShareValueSet($valueSetId: ID!, $personId: ID!, $remove: Boolean) {
    shareValueSet(valueSetId: $valueSetId, personId: $personId, remove: $remove) {
      _id owners { ${personSum} }
    }
  }

`;

export const REQUEST_PROFILE_DATA_IMPORT = gql`
  mutation RequestProfileDataImport($valueKey: String, $global: Boolean) {
    requestProfileDataImport(valueKey: $valueKey, global: $global)
  }
`;

export const FORMAT_VALUES = gql`
  mutation FormatValues($valueFieldId: ID!, $type: FormatValueType!, $rgxStr: String!) {
    formatValues(valueFieldId: $valueFieldId, type: $type, rgxStr: $rgxStr)
  }
`;
