import React from 'react';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/client";
import { NON_SERVICE_SUBMISSIONS_COUNT, CLEAR_NON_SERVICE_SUBMISSIONS, SUBMISSIONS_DATA } from '../../constants/gqls/forms';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     textDecoration: 'none'
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function ClearFormData({docId}) {
  // const classes = useStyles();
  const { data } = useQuery(NON_SERVICE_SUBMISSIONS_COUNT, { variables: { docId }});
  const [clearNonServiceSubmissions, { loading }] = useMutation(CLEAR_NON_SERVICE_SUBMISSIONS, {
    refetchQueries: [ SUBMISSIONS_DATA, NON_SERVICE_SUBMISSIONS_COUNT ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClear = () => {
    clearNonServiceSubmissions({ variables: { docId }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        Total non-service submissions (including draft records): {data? data.countNonServiceSubmissions : ''}
      </Grid>
      <Grid item>
        <LoadingButton variant="outlined" onClick={handleClear} loading={loading}>
          Clear all
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
