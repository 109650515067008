import React from 'react';
import { Divider } from '@mui/material';
import { useMutation } from "@apollo/client";
import ValueField from './ValueFieldInput';
import FunctionBtn from './FunctionBtn';
import { UPDATE_VALUE } from '../../../constants/gqls/dataValues';

function getValue(valueSet, fieldId) {
  let values = valueSet.valueList.filter(v=>v.field._id===fieldId);
  if (values.length) {
    return values[0];
  }
  return null;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSet({valueSet, valueFields, disabled, count, index, dataId}) {
  const [updateValue, { loading }] = useMutation(UPDATE_VALUE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = (valueFieldId, value) => {
    return updateValue({ variables: {
      valueSetId: valueSet._id,
      valueFieldId, value
    }});
  };

  // const handleChange = (event) => {
  //   setText(event.target.value)
  // };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <Divider>
        <FunctionBtn valueSet={valueSet} dataId={dataId}
          count={count} index={index}
          disabled={disabled || loading} />
      </Divider>
      <div>
        { [...valueFields].sort((a,b)=>a.index-b.index).map(valueField=>(
          <ValueField key={valueField._id} disabled={disabled || loading}
            valueField={valueField} 
            value={getValue(valueSet, valueField._id)}
            update={update} />
        ))}
      </div>
    </div>
  )
}
