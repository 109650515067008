import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails,
  Typography, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { makeStyles } from '@material-ui/core/styles';
import PersonalShareItem from '../DocumentShare/PersonalShareItem';
import { DateTimeToString } from '../../constants/utils';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormInfo({doc}) {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const owner = doc.ownerPerson || doc.ownerSpace;
  const editors = doc.sharedToConnection.edges.filter(edge=>edge.access === 'Editor')

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Form info</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { doc.isProfileForm &&
          <p>This is a profile form.</p>
        }
        Data will be submitted to form owner and editors:
        <List>
          <PersonalShareItem entity={owner} access="Owner" />
          {editors.map(item=>(
            <PersonalShareItem key={item.node._id}
              entity={item.node} access={item.access}
              disabled={true} />
          ))}
        </List>
        <p>
          Form available: {
            (doc.formOpen || doc.formClose) ?
              (doc.formOpen ? DateTimeToString(doc.formOpen): 'now') + (doc.formClose ? ' ~ ' + DateTimeToString(doc.formClose) : ' onwards')
              : 'always'
          }
        </p>
        { doc.quota &&
          <p>
            Quota left: {doc.quotaLeft}
          </p>
        }
        { !doc.isProfileForm && 
          <p>
            Each person can submit {doc.multipleSubmit? 'multiple times.' : 'only once.'}
          </p>
        }
      </AccordionDetails>
    </Accordion>
  );
}
