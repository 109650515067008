import React from 'react';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { dataToString, valueSetToString } from '../../constants/utils';
import SortValueSets from './SortValueSets';


//******************************************************************************
// component definition
//******************************************************************************
export default function InputDataView({item, data}) {
  const [html, setHtml] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log(item.input.type);
  // console.log(html);

  React.useEffect(() => {
    if (item.input.type === 'valueSets' && data) {
      setHtml(`<ol>${data.valueSetList.map(vs=>`<li>${valueSetToString(vs)}</li>`).join('\n')}</ol>`);
    } else {
      setHtml(dataToString(data).split('\n').map(p=>`<p>${p}</p>`).join('\n'));
    }
  }, [item, data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSortOpen = (event) => {
    if (data?.valueSetList?.length > 1) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <div onContextMenu={handleSortOpen}>
        <HtmlEditor html={html} readOnly={true}
          placeholder="No data" />
      </div> 
      <SortValueSets
        anchorEl={anchorEl}
        onClose={handleSortClose}
        data={data}
        valueFields={item.input.valueFields} />
    </>
  );
}
