import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
   Button, Autocomplete, TextField } from '@mui/material';
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles } from '@material-ui/core/styles';
import { PROFILE_KEYS_SEARCH, SET_PROFILE_KEY } from '../../constants/gqls/profileKeys';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialogText: {
    '&.MuiDialogContentText-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputProfileKeyDialog({open, handleClose, itemId, profileKey}) {
  const classes = useStyles();
  const [keyValue, setKeyValue] = React.useState(profileKey?.key || '');
  const [showMsg, setShowMsg] = React.useState(profileKey? true : false);
  const [setProfileKey, { loading }] = useMutation(SET_PROFILE_KEY, {
    refetchQueries: [ PROFILE_KEYS_SEARCH ],
  });
  const { data } = useQuery(PROFILE_KEYS_SEARCH, { variables: {
    where: { key_MATCHES: `(?i).*${keyValue}.*`},
    options: { limit: 10 }
  }});
  // console.log(keyValue);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onClose = () => {
    setShowMsg(profileKey? true : false);
    handleClose();
  };

  const handleValueChange = (event, newValue) => {
    if (newValue !== keyValue) {
      setKeyValue(newValue);
    }
  };

  const submit = () => {
    setProfileKey({ variables: {
      itemId, key: keyValue
    }}).then(res=>{
      // console.log(res);
      setShowMsg(res.data.setProfileKey?.input.profileKey? true : false);
      handleClose();
    });
  };

  const closeMsg = () => {
    setShowMsg(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Profile Key</DialogTitle>
      { showMsg ? 
        <>
          <DialogContent>
            <DialogContentText className={classes.dialogText}>
              Please be aware that a profile key will be the key for user profile data. It should not be changed frequently. By selecting another existing profile key, this input will be removed (if it has no related data and not related to other items), and replace by the input related to the selected profile key.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={closeMsg}>Go ahead to change</Button>
          </DialogActions>
        </>
        :
        <>
          <DialogContent>
            <DialogContentText className={classes.dialogText}>
              Every input in a profile form must has a profile key. This key will be a label of the profile data. And different data should not share the same key.
            </DialogContentText>
            <Autocomplete freeSolo id="profile-key" label="Profile key"
              options={data?.profileKeys.map((option) => option.key) || []}
              renderInput={(params) => <TextField {...params} />}
              onInputChange={handleValueChange} 
              value={keyValue}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={submit} disabled={loading}>
              OK
            </Button>
          </DialogActions>
        </>
      }
    </Dialog>
  )
}
