import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import { dataMap, formFilledCheck } from '../../constants/cache';
import { TUT_HOUR_IMPORTED_DATA } from '../../constants/gqls/forms';

// function getOptions(importedData, filterData, thisValue) {
//   // console.log(importedData);
//   // console.log(filterData);
//   if (!importedData || importedData.length === 0 || !filterData || filterData.length === 0) {
//     return [[], []]
//   }
//   // let thisFilterOptions = filterData.options[0]._id;
//
//   let dayOptions = [
//     { label: 'Monday', in: false },
//     { label: 'Tuesday', in: false },
//     { label: 'Wednesday', in: false },
//     { label: 'Thursday', in: false },
//     { label: 'Friday', in: false },
//     { label: 'Saturday', in: false },
//   ];
//   let dataValues = [], thisValueFound = false;
//
//   for (var i = 0; i < importedData.length; i++) {
//     // let values = importedData[i].values;
//     // let filterOptionId = importedData[i].filterOptionId;
//     // let match = true;
//     // for (var j = 0; j < filterOptions.length; j++) {
//     //   if (filterOptions[j] !== thisFilterOptions[j]) {
//     //     match = false;
//     //   }
//     // }
//     if (importedData[i].filterOptionId === filterData[0].options[0]._id) {
//       dataValues = importedData[i].values;
//     }
//     // let subset = values.map(value=>({ filterOptions, value }));
//   }
//   dataValues = Array.from(new Set(dataValues));
//   // console.log(dataValues);
//
//   let dataOptions = dataValues.map(value=>{
//     if (thisValue === value) {
//       thisValueFound = true
//     }
//     let [day, label] = value.split(' => ');
//     switch (day) {
//       case 'Monday':
//         dayOptions[0].in = true;
//         break;
//       case 'Tuesday':
//         dayOptions[1].in = true;
//         break;
//       case 'Wednesday':
//         dayOptions[2].in = true;
//         break;
//       case 'Thursday':
//         dayOptions[3].in = true;
//         break;
//       case 'Friday':
//         dayOptions[4].in = true;
//         break;
//       case 'Saturday':
//         dayOptions[5].in = true;
//         break;
//       default:
//         break;
//     }
//     return{ day, label, value }
//   });
//
//   dayOptions = dayOptions.filter(opt=>opt.in).map(opt=>opt.label);
//   return [dayOptions, dataOptions, thisValueFound];
// }

function getOptions(importedData, filterData) {
  if (!importedData) {
    return [];
  }
  // initial options
  let options = importedData.weeklyDayOptions.map(item=>({
    label: item,
    disabled: true,
    timeOptions: []
  }));
  // check filterData
  if (filterData?.length !== importedData.dataFilterCount) {
    return options;
  }
  let optObj = {};
  for (var i = 0; i < importedData.dataItems.length; i++) {
    let filterOptionIds = importedData.dataItems[i].filterOptionIds;
    let filterDataMatchs = filterData.filter(data=>filterOptionIds.includes(data.options[0]._id));
    if (filterDataMatchs.length === importedData.dataFilterCount) {
      let [dayValue, timeValue] = importedData.dataItems[i].value.split(' => ');
      if (!optObj[dayValue]) {
        optObj[dayValue] = { timeObj: {} };
      }
      optObj[dayValue].timeObj[timeValue] = { value: importedData.dataItems[i].value }
    }
  }
  for (i = 0; i < options.length; i++) {
    let optItem = optObj[options[i].label];
    options[i].disabled = !optItem;
    if (optItem) {
      options[i].timeOptions = importedData.weeklyHourOptions.map(item=>(
        optItem.timeObj[item]?
          { label: item,  disabled: false, value: optItem.timeObj[item].value }
          : { label: item,  disabled: true, value: '' }
      ));
    }
  }
  return options;
}

// function getTimeOptions(swesImportedData, day, filterData, thisValue) {
//   if (!swesImportedData) {
//     return [];
//   }
//   // initial options
//   let options = swesImportedData.weeklyHourOptions.map(item=>({label: item, disabled: true}));
//   // check filterData
//   if (filterData.length !== swesImportedData.dataFilterCount || !day) {
//     return options;
//   }
//   let optObj = {};
//   for (var i = 0; i < swesImportedData.dataItems.length; i++) {
//     let filterDataMatchs = filterData.filter(data=>(
//       swesImportedData.dataItems[i].filterOptionIds.includes(data.options[0]._id) &&
//       swesImportedData.dataItems[i].value.includes(day)
//     ));
//     if (filterDataMatchs.length === swesImportedData.dataFilterCount) {
//       let [_, timeValue] = swesImportedData.dataItems[i].value.split(' => ');
//       optObj[timeValue] = true;
//     }
//   }
//   for (var i = 0; i < options.length; i++) {
//     options[i].disabled = !optObj[options[i].label];
//     options[i].value = day+' => '+options[i].label;
//   }
//   return options;
// }

//******************************************************************************
// component definition
//******************************************************************************
export default function TutoringHour({id, disabled, value, updateData}) {
  const dataMapVar = useReactiveVar(dataMap);
  const { data } = useQuery(TUT_HOUR_IMPORTED_DATA, { variables: {
    inputId: id,
  }});
  const [options, setOptions] = React.useState([]);
  // const [dayOptions, setDayOptions] = React.useState([]);
  const [dataOptions, setDataOptions] = React.useState([]);
  const [day, setDay] = React.useState('');
  const filterData = dataMapVar?.get('data-filter');

  // console.log(data);
  // console.log(filterData);
  // console.log(options);

  // React.useEffect(() => {
  //   if (data) {
  //     let oldCheck = formFilledCheck();
  //     let [dayOpts, dataOpts, valueFound] = getOptions(data.swesImportedData, filterData, value);
  //     setDayOptions(dayOpts);
  //     setDataOptions(dataOpts);
  //     if (valueFound) {
  //       setDay(value.split(' => ')[0]);
  //       oldCheck[id] = true;
  //       formFilledCheck(oldCheck);
  //       let checked = true;
  //       for (const property in oldCheck) {
  //         if (!oldCheck[property]) {
  //           checked = false;
  //         }
  //       }
  //       formFilledOK(checked);
  //     } else {
  //       setDay(dayOpts[0]);
  //       oldCheck[id] = false;
  //       formFilledCheck(oldCheck);
  //       formFilledOK(false);
  //     }
  //   }
  // }, [data, filterData, value, id]);

  React.useEffect(() => {
    if (data) {
      let opts = getOptions(data.tutHourImportedData, filterData);
      setOptions(opts);
    }
  }, [data, filterData]);

  React.useEffect(() => {
    if (day && options.length !== 0) {
      let opts = options.filter(opt=>opt.label===day)[0]?.timeOptions;
      setDataOptions(opts || []);
    }
  }, [day, options]);

  React.useEffect(() => {
    let oldCheck = {...formFilledCheck()};
    let valueFound = false;
    if (value) {
      let [dayValue, ] = value.split(' => ');
      let opts = options.filter(opt=>opt.label===dayValue)[0]?.timeOptions;
      if (opts) {
        valueFound = opts.filter(opt=>opt.value===value)[0];
        if (valueFound) {
          setDay(dayValue);
          oldCheck[id] = true;
          formFilledCheck(oldCheck);
        }
      }
    }
    if (!valueFound) {
      oldCheck[id] = false;
      formFilledCheck(oldCheck);
    }
  }, [id, value, options]);

  // React.useEffect(() => {
  //   if (value) {
  //     setDay(value.split(' => ')[0])
  //   }
  // }, [value]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const handleChange = (event) => {
    updateData({
      value: event.target.value
    }).then(res => {
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <FormControl fullWidth id={id} disabled={disabled}>
          <InputLabel>Select week day</InputLabel>
          { <Select id={'tutoring-day-'+id} label="Select week day"
              value={day} onChange={handleDayChange}
            >
              { options.map(option=>
                <MenuItem key={option.label} value={option.label} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              )}
            </Select>
          }
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth id={id} disabled={disabled}>
          <InputLabel>Select time</InputLabel>
          { <Select id={'tutoring-hour-'+id} label="Select time"
              value={value || ''} onChange={handleChange}
            >
              { dataOptions.map((option, i)=>
                <MenuItem key={i} value={option.value} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              )}
            </Select>
          }
        </FormControl>
      </Grid>
      { value &&
        <Grid item xs={12}>
          Selected: {value}
        </Grid>
      }
    </Grid>
  )
}
