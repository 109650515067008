import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ValueSet from './ValueSets/ValueSet';
import { CREATE_VALUE_SET } from '../../constants/gqls/dataValues';
import { PROFILE_DATA } from '../../constants/gqls/forms';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  },
}));


//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetInput({item, data, submissionId, profileId, setSubId, disabled}) {
  const classes = useStyles();
  const [createValueSet, { loading }] = useMutation(CREATE_VALUE_SET, {
    refetchQueries: [ PROFILE_DATA ],
  });
  // const [value, setValue] = React.useState(data?.value || '');
  console.log(data);

  // React.useEffect(() => {
  //   // console.log(data);
  //   setValue(data?.value || '');
  // }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleCreate = () => {
    createValueSet({ variables: {
      itemId: item._id,
      dataId: data?._id,
      submissionId, profileId
    }}).then(res => {
      console.log(res);
      let newSubId = res?.data?.createValueSet.submission[0]?._id;
      setSubId(newSubId);
    })
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { data? [...data.valueSetList].map((valueSet, index)=>(
        <ValueSet key={valueSet._id} 
          count={data.valueSetList.length} index={index}
          valueSet={valueSet} disabled={disabled}
          valueFields={item.input.valueFields}
          dataId={data._id} />
      )) : ''}
      <div className={classes.btnBox}>
        <Button onClick={handleCreate} disabled={disabled || loading}>
          Add Item
        </Button>
      </div>
    </div>
  )
}
