import React from 'react';
import { TextField, Grid, FormControlLabel, Switch, FormControl, FormGroup, FormHelperText, Autocomplete } from '@mui/material';
import { useQuery } from "@apollo/client";
import { VALUE_FIELDS } from '../../constants/gqls/dataValues';

//******************************************************************************
// component definition
//******************************************************************************
export default function ImportArgs({ valueKey, setValueKey, global, setGlobal }) {
  const [options, setOptions] = React.useState([]);
  const { loading, data } = useQuery(VALUE_FIELDS);

  React.useEffect(() => {
    if (data) {
      let optSet = new Set(data.valueFields.map(vf=>vf.name));
      setOptions(Array.from(optSet));
    }
  }, [data]);
  // console.log(valueKey);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleVKChange = (event, newValue) => {
    setValueKey(newValue);
    if (!newValue) {
      setGlobal(false);
    }
  }

  const handleGlobalChange = (event) => {
    setGlobal(event.target.checked)
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={6}>
        <FormControl disabled={loading}>
          <FormGroup>
            <Autocomplete id="value-key"
              value={valueKey}
              onChange={handleVKChange}
              options={options}
              renderInput={(params) => 
                <TextField {...params} fullWidth label="Key Field" />
              }
            />
          </FormGroup>
          <FormHelperText>
            Define the key field for Value Set data can avoid duplicate records. It's meaningless for other input types, because the key will always be email.
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl disabled={!valueKey}>
          <FormGroup>
            <FormControlLabel control={
              <Switch checked={global} onChange={handleGlobalChange} />
            } label="Global" />
          </FormGroup>
          <FormHelperText>
            Turn it on to allow different profile owners to share a value set.
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
