import React from 'react';
import { Button } from '@mui/material';
import InputProfileKeyDialog from './InputProfileKeyDialog';

//******************************************************************************
// component definition
//******************************************************************************
export default function InputProfileKeyBtn({itemId, profileKey}) {
  const [open, setOpen] = React.useState(false);
  // console.log(importFormId);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button onClick={handleOpen}>
        { profileKey? profileKey.key : 'Profile Key' }
      </Button>
      <InputProfileKeyDialog open={open} handleClose={handleClose}
        itemId={itemId} profileKey={profileKey} />
    </React.Fragment>
  )
}
