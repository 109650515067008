// @ts-check
import React from 'react';
import { useParams } from "react-router-dom";
import { LinearProgress, Container } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import DocumentPage from '../components/Pages/DocumentPage';
import PageBanner from '../components/Pages/PageBanner';
import NotFount from './NotFount';
import { PAGE_VIEW } from '../constants/gqls/pages';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '18vh',
    paddingBottom: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function PageView() {
  const params = useParams();
  const classes = useStyles();
  const { loading, data } = useQuery(PAGE_VIEW, {
    variables: {
      where: { name: params.name }
    }
  });
  console.log(data);


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if ((!data.pages)
    || data.pages.length === 0
    || (data.pages[0].role === 'viewer' && !data.pages[0].active)) {
    return <NotFount />
  }

  if (data.pages[0].content?.type === 'Document') {
    return (
      <>
        <PageBanner />
        <Container maxWidth="lg" className={classes.container}>
          <DocumentPage docId={data.pages[0].content._id} />
        </Container>
      </>
    );
  }

  return '';
}
