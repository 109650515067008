import React from 'react';
import { TextField } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from "@apollo/client";
import { PAGE_NAME_CHECK } from '../../constants/gqls/pages';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   stack: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(3),
//   },
//   btn: {
//     width: '180px'
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function PageNameInput({name, setName, nameCheck, setNameCheck, originalValue}) {
  // const classes = useStyles();
  const [pageNameCheck] = useLazyQuery(PAGE_NAME_CHECK, { fetchPolicy: "network-only" });
  // console.log(originalValue);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
    // console.log(event.target.value);
    if (event.target.value !== originalValue) {
	    pageNameCheck({ variables: { name: event.target.value }}).then(res=>{
	      setNameCheck(res.data.pageNameCheck);
	      // console.log(res);
	    });
    } else {
      setNameCheck(true);
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField id="tag-key" fullWidth margin="dense"
      variant="standard" label="Name"
      value={name} onChange={onNameChange}
      error={name !== '' && !nameCheck} helperText="Page name should be unique." />
);
}
