import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function ParticipantEdit({options, participants, update}) {
  const ppIds = participants.map(pp=>pp._id);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpdate = (event) => {
    let [role, id] = event.target.name.split(' ');
    if (event.target.checked) {
      update({
        participants: [{
          connect: [{ 
            where: { node: { _id: id }},
            edge: { role }
          }]
        }]
      });
    } else {
      update({
        participants: [{
          disconnect: [{ where: {
            node: { _id: id }
          }}]
        }]
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControl>
      <FormGroup>
        { options.map(opt=>(
          <FormControlLabel key={opt.node._id}
            label={opt.node.name}
            control={
              <Checkbox name={opt.role+' '+opt.node._id}
                checked={ppIds.includes(opt.node._id)}
                onChange={handleUpdate} />
            } />
        ))}
      </FormGroup>
    </FormControl>
  );
}
