import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormSubList from './FormSubList';
import FormProfileList from './FormProfileList';


//******************************************************************************
// component definition
//******************************************************************************
export default function FormSubmissions({doc}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{doc.isProfileForm ? "Profiles" : "Submissions"}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { doc.isProfileForm ? 
          <FormProfileList docId={doc._id} />
          :
          <FormSubList docId={doc._id} />
        }
      </AccordionDetails>
    </Accordion>
  );
}
