import React from 'react';
import { useSearchParams } from "react-router-dom";
// import { Typography } from '@mui/material';
import { useMutation, useReactiveVar, useQuery } from "@apollo/client";
import { formInputEnabled, formSubmitted } from '../../constants/cache';
import ItemTextEdit from './ItemTextEdit';
import ItemTextView from './ItemTextView';
import InputItemSetting from '../Inputs/InputItemSetting';
import InputItemView from '../Inputs/InputItemView';
import LinkContent from '../Links/LinkContent';
import { UPDATE_ITEM } from '../../constants/gqls/items';
import { ME } from '../../constants/gqls/user';

//******************************************************************************
// component definition
//******************************************************************************
export default function ItemContent({item, setFocus, editMode, readMode, docIsProfileForm}) {
  const [searchParams] = useSearchParams();
  const { data } = useQuery(ME);
  const [editData, setEditData] = React.useState(false);
  const inputEnabled = useReactiveVar(formInputEnabled);
  const submitted = useReactiveVar(formSubmitted);
  const [updateItem, { loading }] = useMutation(UPDATE_ITEM);
  // const editMode = item.editable && mode !== 'view';

  React.useEffect(() => {
    if (searchParams.get("action")==='editData' && data?.me?.roles?.includes('admin')) {
      setEditData(true);
    } else {
      setEditData(false);
    }
  }, [searchParams, data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const updateText = (text) => {
    return updateItem({ variables: {
      itemId: item._id, text
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  switch (item.type) {
    case 'text':
      if (editMode) {
        return <ItemTextEdit itemText={item.text} loading={loading} editItem={updateText} setFocus={setFocus} />
      }
      return (
        <ItemTextView itemText={item.text} />
      );
    case 'filter':
    case 'input':
      if (editMode) {
        return (<InputItemSetting item={item} 
            loading={loading} 
            updateText={updateText} 
            setFocus={setFocus} />)
      }
      return (<InputItemView item={item} readMode={readMode}
        docIsProfileForm={docIsProfileForm}
        disabled={(submitted || !inputEnabled) && !editData} />)
    case 'link':
      return <LinkContent link={item.link} />
    default:
      return <div />
  }
}
