import React from 'react';
// import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import AttendanceItem from './AttendanceItem';
import { SERVICE_SESSION_ATTENDANCES } from '../../constants/gqls/attendances';

//******************************************************************************
// component definition
//******************************************************************************
export default function SSAttendancesList({serviceSessionId, sParticipants}) {
  const { loading, data } = useQuery(SERVICE_SESSION_ATTENDANCES, {
    variables: { serviceSessionId }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.serviceSessionAttendances.length === 0) {
    return 'No attendance yet.';
  }

  return (
    <div>
      { data.serviceSessionAttendances.map((attendance, i)=>(
        <AttendanceItem key={attendance._id}
          attendance={attendance} 
          sParticipants={sParticipants}
          index={i} />
      )) }
    </div>
  );
}
