const SiteMap = {
  home: '/',
  login: '/login',
  tags: '/tags',
  tagsFilter: '/tags/filter',
  // documents: '/documents',
  myDocuments: '/documents/my',
  sharedDocuments: '/documents/shared',
  document: '/documents/:id',
  formData: '/documents/:id/data',
  spaces: '/spaces',
  space: '/spaces/:id',
  person: '/people/:id',
  email_template: '/emails/:id',
  services: '/services',
  certificates: '/certificates',
  certificate: '/certificates/:id',
  certRequest: '/certificates/:id/request',
  certCheck: '/certificates/check/:id',
  service: '/services/:key',
  serviceSession: '/services/sessions/:id',
  serviceSessionsData: '/services/:id/sessions/data/:year',
  pageManage: '/pages/manage/:id',
  pageView: '/pages/:name',
  admin_users: '/admin/users',
  admin_profiles: '/admin/profiles',
  // admin_profiles_data: '/admin/profiles/data',
  admin_tags: '/admin/tags',
  admin_pages: '/admin/pages',
  admin_emails: '/admin/emails',
  admin_certs: '/admin/certificates',
  admin_cert: '/admin/certificates/:id',
  admin_data_analysis: '/admin/data-analysis',
  admin_data_table_details: '/admin/data-analysis/:id',
  admin_data_table: '/admin/data-analysis/:id/data',
};

export default SiteMap;
