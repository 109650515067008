import React from 'react';
// import queryString from 'query-string';
import { useParams, useSearchParams } from "react-router-dom";
import { LinearProgress, Container, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import TagsShowcase from '../components/TagsShowcase';
import DocPaper from '../components/Document/DocPaper';
import FormArea from '../components/Forms/FormArea';
import SubmissionQuery from '../components/Document/SubmissionQuery';
import SubmissionClear from '../components/Document/SubmissionClear';
import NotFount from './NotFount';
import { DOCUMENT } from '../constants/gqls/documents';
// import { currentSubmission } from '../constants/cache';
// import { TAGS_FOR_ADD } from '../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Document() {
  // const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  // const query = queryString.parse(location.search);
  const classes = useStyles();
  // const submission = useReactiveVar(currentSubmission);
  const [mode, setMode] = React.useState(null);
  const [submission, setSubmission] = React.useState(null);
  const { loading, data } = useQuery(DOCUMENT, { variables: {
    docId: params.id,
    // sort: [{ edge: { index: 'ASC' }}]
  }});
  // console.log(submission);
  React.useEffect(() => {
    // dataMap(null);
    // formFilledCheck({});
    // formFilledOK(false);
    if (data?.document?.isForm) {
      setMode('view');
    }
    let modePrm = searchParams.get("mode")
    if (modePrm) {
      if (modePrm === 'clean') {
        modePrm = 'view'
      }
      setMode(modePrm);
    }
    if (searchParams.get("submission")) {
      setSubmission(searchParams.get("submission"));
      setMode('view');
    } else if (searchParams.get("profile")) {
      setMode('view');
    } else {
      setSubmission(null);
    }
  }, [searchParams, params, data]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.document) return <NotFount />

  return (
    <React.Fragment>
      <div className={classes.box}>
        <TagsShowcase emptyLabel="No tags yet, click tag button on the left to add one."
          objId={data.document._id} tags={data.document.tags} />
      </div>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg="auto">
            <DocPaper doc={data.document} mode={mode} />
            { submission &&
              <SubmissionQuery myLastSub={data.document.myLastSub} submissionId={submission} />
            }
            { !submission &&
              <SubmissionClear />
            }
          </Grid>
          <FormArea doc={data.document} mode={mode} />
        </Grid>
      </Container>
    </React.Fragment>
  );
}
