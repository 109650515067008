import { gql } from '@apollo/client';
import { docDetails, itemDetails, tagSum, sharedTo } from './fragments';

export const MY_DOCUMENTS = gql`
  query MyDocuments($offset: Int!, $limit: Int!) {
    myDocuments(offset: $offset, limit: $limit) {
      _id title description tags { ${tagSum} }
    }
    myDocumentsCount
  }
`;

export const SHARED_DOCUMENTS = gql`
  query SharedDocuments($offset: Int!, $limit: Int!) {
    sharedDocuments(offset: $offset, limit: $limit) {
      _id title description tags { ${tagSum} }
    }
    sharedDocumentsCount
  }
`;

export const DOCUMENT_CHOWN = gql`
  mutation DocumentChown($docId: ID!, $owner: ID!) {
    documentChown(docId: $docId, owner: $owner) {
      _id isOwner
    }
  }
`;

export const DOCUMENT = gql`
  query Document($docId: ID!) {
    document(id: $docId) {
      ${docDetails}
    }
  }
`;

export const DOCUMENT_VIEW = gql`
  query Document($docId: ID!) {
    document(id: $docId) {
      _id title description editable isOwner isForm
      inputEnabled formOpen formClose quota multipleSubmit myLastSub canSubmit quotaLeft
      submitPrompt { _id promptTemplate }
      itemsConnection {
        totalCount edges {
          index node { ${itemDetails} }
        }
      }
    }
  }
`;

export const DOCUMENT_ISFORM = gql`
  query DocumentIsForm($docId: ID!) {
    document(id: $docId) {
      _id isForm multipleSubmit myLastSub canSubmit quotaLeft
    }
  }
`;

export const DOCUMENT_SEARCH = gql`
  query DocumentSearch($search: String) {
    documentSearch(search: $search) {
      _id title description
    }
  }
`;

// export const DOCUMENT_PREV = gql`
//   query DocumentPrev($docId: ID!) {
//     document(id: $docId) {
//       _id title description tags { ${tagSum} }
//     }
//   }
// `;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($title: String, $description: String, $spaceId: ID) {
    createDocument(title: $title, description: $description, spaceId: $spaceId) {
      documents { _id }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($docId: ID!, $update: DocumentUpdateInput!) {
    updateDocument(docId: $docId, update: $update) {
      documents {
        _id title description scope updatedAt formOpen formClose quota multipleSubmit inputEnabled isProfileForm quotaLeft
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($documentId: ID!) {
    deleteDocument(id: $documentId)
  }
`;

// export const TAG_DOCUMENT = gql`
//   mutation TagDocument($where: DocumentWhere, $connect: DocumentConnectInput, $disconnect: DocumentDisconnectInput) {
//     updateDocuments(where: $where, connect: $connect, disconnect: $disconnect) {
//       documents {
//         _id tags { ${tagSum} }
//       }
//     }
//   }
// `;

export const SHARE_DOCUMENT = gql`
  mutation DocumentSharing($docId: ID!, $unShare: [ID], $share: [ID], $access: AccessRight) {
    documentSharing(docId: $docId, unShare: $unShare, share: $share, access: $access) {
      _id ${sharedTo}
    }
  }
`;

export const SORT_ITEMS = gql`
  mutation SortItems($docId: ID!, $item: ID, $index: Int) {
    sortItems(id: $docId, item: $item, index: $index) {
      _id itemsConnection { edges { index node { _id }}}
    }
  }
`;

// export const ITEM_PREV = gql`
//   query ItemPrev($where: ItemWhere) {
//     items(where: $where) {
//       ${itemDetails} ownerDoc { _id }
//     }
//   }
// `;

// export const CREATE_ITEM = gql`
//   mutation CreateItem($index: Int, $text: String, $document: ID) {
//     createItem(index: $index, text: $text, document: $document) {
//       _id
//     }
//   }
// `;
//
// export const UPDATE_ITEM = gql`
//   mutation UpdateItem($where: ItemWhere, $update: ItemUpdateInput) {
//     updateItems(where: $where, update: $update) {
//       items {
//         _id type text
//       }
//     }
//   }
// `;
//
// export const DELETE_ITEM = gql`
//   mutation DeleteItem($where: ItemWhere, $linkWhere: LinkWhere) {
//     deleteLinks(where: $linkWhere) {
//       nodesDeleted
//     }
//     deleteItems(where: $where) {
//       nodesDeleted
//     }
//   }
// `;

// export const TAG_ITEM = gql`
//   mutation TagItem($where: ItemWhere, $connect: ItemConnectInput, $disconnect: ItemDisconnectInput) {
//     updateItems(where: $where, connect: $connect, disconnect: $disconnect) {
//       items {
//         _id tags {
//           ${tagSum}
//         }
//       }
//     }
//   }
// `;

export const MY_PROFILE_fORMS = gql`
  query MyProfileForms($offset: Int, $limit: Int) {
    myProfileForms(offset: $offset, limit: $limit) {
      _id title
    }
    myProfileFormsCount
  }
`;
