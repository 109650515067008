import React from 'react';
import { useParams } from "react-router-dom";
import { generatePath } from "react-router-dom";
import { Container, Paper, LinearProgress, CardHeader, 
  Table, TableBody, TableRow, TableCell, Button, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import CreateOrEdit from '../components/Certificates/CreateOrEdit';
import FunctionBtn from '../components/Certificates/FunctionBtn';
import NotFount from './NotFount';
import { CERT_TYPE } from '../constants/gqls/certificates';
import ViewTemplateBtn from '../components/Certificates/ViewTemplateBtn';
import CertificatesList from '../components/Certificates/CertificatesList';
import CertTypeActions from '../components/Actions/CertTypeActions';
import SiteMap from '../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  secondPaper: {
    borderTop: '1px dashed '+theme.palette.primary.light
  },
  labelText: {
    color: theme.palette.secondary.main
  },
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertType() {
  const classes = useStyles();
  const params = useParams();
  const [mode, setMode] = React.useState('view');
  const { loading, data } = useQuery(CERT_TYPE, { variables: {
    id: params.id
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.certType) return <NotFount />

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title={data.certType.name} action={
          data.certType.myRole?
          <FunctionBtn certType={data.certType}
            mode={mode} setMode={setMode}
            delable={data.certType.issuedCertCount===0} />
          : null
        } />
        { mode === 'view'? 
          <>
            <CardContent>
              <div dangerouslySetInnerHTML={{ 
              __html: data.certType.description 
              }} />
            </CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <span className={classes.labelText}>Published:</span>
                  </TableCell>
                  <TableCell>
                    {data.certType.publish? 'Yes':'No'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <span className={classes.labelText}>Managers:</span>
                  </TableCell>
                  <TableCell>
                    {data.certType.managers.map(mgr=>(
                      <div key={mgr._id}>
                        {mgr.name+' <'+mgr.email+'>'}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
                { data.certType.myRole && 
                  <>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <span className={classes.labelText}>Variables:</span>
                      </TableCell>
                      <TableCell>
                        {data.certType.variables.map(v=>(v.key)).join(', ')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <span className={classes.labelText}>Template:</span>
                      </TableCell>
                      <TableCell>
                        { data.certType.hasTemplate? 
                          <ViewTemplateBtn certTypeId={params.id} /> 
                          : 'None'
                        }
                      </TableCell>
                    </TableRow>
                  </>
                }
                <TableRow>
                  <TableCell className={classes.cell}>
                    <span className={classes.labelText}>Request form:</span>
                  </TableCell>
                  <TableCell>
                    <Button href={generatePath(SiteMap.certRequest, { id: params.id })}>View form</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
          : 
          <CreateOrEdit certType={data.certType}
            setMode={setMode} isAdmin={data.certType.myRole === 'admin'} />
        }
      </Paper>
      { data.certType.myRole &&
        <>
          <CertTypeActions certTypeId={params.id} />
          <Paper className={classes.secondPaper}>
            <CertificatesList certTypeId={params.id} />
          </Paper>
        </>
      }
    </Container>
  );
}
