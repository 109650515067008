import { gql } from '@apollo/client';
import { personSum, dataDetails } from './fragments';

export const MY_SUBMISSIONS = gql`
  query MySubmissions($docId: ID!, $offset: Int, $limit: Int) {
    mySubmissions(docId: $docId, offset: $offset, limit: $limit) {
      _id createdAt submitted
    }
    mySubmissionsCount(docId: $docId)
  }
`;

export const SUBMISSIONS = gql`
  query Submissions($docId: ID!, $offset: Int, $limit: Int) {
    submitted(docId: $docId, offset: $offset, limit: $limit) {
      _id submitted submittedTime serviceSessionId
      ownerPerson { ${personSum} }
    }
    submittedCount(docId: $docId)
  }
`;

export const DOC_SHARED_TO = gql`
  query DocSharedTo($docId: ID!, $offset: Int, $limit: Int) {
    docSharedTo(docId: $docId, offset: $offset, limit: $limit) {
      ${personSum}
    }
    docSharedToCount(docId: $docId)
  }
`;

export const SUBMISSIONS_DATA = gql`
  query SubmissionsData($docId: ID!) {
    submittedAll(docId: $docId) {
      _id submittedTime serviceSessionId
      ownerPerson { _id name email studyLevel }
      data { 
        ${dataDetails} input { _id }
      }
    }
    document(id: $docId) {
      _id title inputItems { _id text input { _id }}
    }
  }
`;

export const PROFILE_DATA = gql`
  query ProfileKeyData($keyId: ID!, $profileId: ID) {
    profileKeyData(keyId: $keyId, profileId: $profileId) {
      ${dataDetails}
    }
  }
`;

export const SUBMISSION = gql`
  query Submission($submissionId: ID!) {
    submission(id: $submissionId) {
      _id submitted serviceSessionId data {
        _id value values boolValue input { _id type isDataFilter } options { _id quotaLeft }
      }
    }
  }
`;

export const UNIQUE_CHECK = gql`
  query UniqueCheck($dataId: ID!) {
    uniqueCheck(dataId: $dataId)
  }
`;

export const OPTIONS_IN_FORM = gql`
  query OptionsInForm($formId: ID!) {
    optionsInForm(formId: $formId) {
      _id text
    }
  }
`;

export const DELETE_SUBMISSION = gql`
  mutation DeleteSubmission($subId: ID!) {
    deleteSubmission(subId: $subId)
  }
`;

export const SUBMIT = gql`
  mutation Submit($subId: ID!) {
    submit(subId: $subId) {
      submissions {
        _id submitted submittedTime serviceSessionId
      }
    }
  }
`;

export const UNDO_SUBMIT = gql`
  mutation UndoSubmit($subId: ID!) {
    undoSubmit(subId: $subId) {
      _id submitted
    }
  }
`;

export const CREATE_DATA = gql`
  mutation AuthCreateData($itemId: ID!, $submissionId: ID, $value: String, $values: [String], $boolValue: Boolean $optionIds: [ID], $profileId: ID) {
    authCreateData(itemId: $itemId, submissionId: $submissionId, value: $value, values: $values, boolValue: $boolValue, optionIds: $optionIds, profileId: $profileId) {
      _id submission {
        _id submitted
      }
    }
  }
`;

export const UPDATE_DATA = gql`
  mutation AuthUpdateData($dataId: ID!, $value: String, $values: [String], $boolValue: Boolean, $optionIds: [ID]) {
    authUpdateData(dataId: $dataId, value: $value, values: $values, boolValue: $boolValue, optionIds: $optionIds) {
      data {
        _id value values boolValue options { _id }
      }
    }
  }
`;

export const DELETE_DATA = gql`
  mutation AuthDeleteData($itemId: ID!, $submissionId: ID!) {
    authDeleteData(itemId: $itemId, submissionId: $submissionId)
  }
`;

export const IMPORT_FORMS = gql`
  query ImportForms($docId: ID!) {
    importForms(docId: $docId) {
      _id title 
      nonDataFilterItems {
        _id text input { _id }
      }
    }
  }
`;

export const TUT_HOUR_IMPORTED_DATA = gql`
  query TutHourImportedData($inputId: ID!) {
    tutHourImportedData(inputId: $inputId) {
      dataFilterCount weeklyDayOptions weeklyHourOptions dataItems {
        filterOptionIds value
      }
    }
  }
`;

export const APPLICATION_IMPORTED_DATA = gql`
  query ApplicationImportedData($inputId: ID!, $filterWeeklyHour: String) {
    applicationImportedData(inputId: $inputId, filterWeeklyHour: $filterWeeklyHour) {
      _id filterDataOptIds
      ownerPerson { _id name email studyLevel }
      dataItems {
        _id value values 
        options { _id text }
        input { itemText 
          profileKey { _id key }
        }
      }
    }
  }
`;

export const APPLICATION_SELECTED_DATA = gql`
  query ApplicationSelectedData($inputId: ID!, $selected: [ID!]!) {
    applicationSelectedData(inputId: $inputId, selected: $selected) {
      _id
      ownerPerson { _id name email studyLevel }
      dataItems {
        _id value values 
        options { _id text }
        input { itemText 
          profileKey { _id key }
        }
      }
    }
  }
`;

export const SERVICE_FORMS = gql`
  query ServiceForms($serviceId: ID!) {
    serviceForms(serviceId: $serviceId) {
      _id title description
    }
  }
`;

export const SERVICE_FORM_SUBMISSIONS = gql`
  query ServiceFormSubmissions($docId: ID!) {
    submittedAll(docId: $docId) {
      _id serviceSessionId
      ownerPerson { _id name email studyLevel }
    }
  }
`;

export const USER_TRIGGER_EMAIL = gql`
  mutation SendUserTriggerEmail($actionId: ID!) {
    sendUserTriggerEmail(actionId: $actionId)
  }
`;

export const NON_SERVICE_SUBMISSIONS_COUNT = gql`
  query CountNonServiceSubmissions($docId: ID!) {
    countNonServiceSubmissions(docId: $docId)
  }
`;

export const CLEAR_NON_SERVICE_SUBMISSIONS = gql`
  mutation ClearNonServiceSubmissions($docId: ID!) {
    clearNonServiceSubmissions(docId: $docId)
  }
`;
