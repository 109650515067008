import React from 'react';
import { FormControl, FormGroup } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import { dataMap } from '../../constants/cache';
import { APPLICATION_IMPORTED_DATA } from '../../constants/gqls/forms';
import ApplicationFilterOption from './ApplicationFilterOption';
import ApplicationFilterDisabled from './ApplicationFilterDisabled';

function getOptions(importedData, filterData, hasFilterInput) {
  if (!filterData) {
    return hasFilterInput? [] : importedData;
  }
  // assuming all filter inputs radio choice
  let thisFilterOptIds = filterData.map(data=>(
    data.options[0]._id
  )).sort().join(' ');
  let options = importedData.filter(sub=>{
    let subFilterOptIds = [...sub.filterDataOptIds].sort().join(' ');
    return thisFilterOptIds === subFilterOptIds;
  });
  return options;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ApplicationFilter({input, disabled, values, updateData}) {
  const dataMapVar = useReactiveVar(dataMap);
  const filterData = dataMapVar?.get('data-filter');
  const weeklyHourData = dataMapVar?.get('weeklyHour');
  const { data } = useQuery(APPLICATION_IMPORTED_DATA, { variables: {
    inputId: input._id,
    filterWeeklyHour: weeklyHourData? weeklyHourData[0].value : null
  }});
  const [options, setOptions] = React.useState([]);

  // console.log(dataMapVar);
  // console.log(data);
  // console.log(filterData);
  // console.log(options);

  React.useEffect(() => {
    if (data) {
      let opts = getOptions(data.applicationImportedData, filterData, input.hasFilterInput);
      setOptions(opts);
      // let optionIds = opts.map(opt=>opt._id);
      // let newValues = values.filter(v=>optionIds.includes(v));
      // console.log(newValues);
      // updateData({
      //   values: newValues
      // }).then(res => {
      //   // console.log(res);
      // });
    }
  }, [data, filterData, input.hasFilterInput]);

  React.useEffect(() => {
    if (values && values.length && !disabled) {
      let optionIds = options.map(opt=>opt._id);
      let newValues = values.filter(v=>optionIds.includes(v));

      if (values.length !== newValues.length) {
        updateData({
          values: newValues
        }).then(res => {
          // console.log(res);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    let newValues = [];
    if (event.target.checked) {
      newValues = [...values, event.target.name];
    } else {
      newValues = values.filter(v=>v!==event.target.name);
    }
    // console.log(newValues);
    updateData({
      values: newValues
    }).then(res => {
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (disabled) {
    return <ApplicationFilterDisabled input={input} values={values} />
  }
  return (
    <FormControl>
      <FormGroup>
        { options.map(option=>(
          <ApplicationFilterOption key={option._id}
            option={option} values={values}
            serviceId={input.service._id}
            handleChange={handleChange} />
        ))}
      </FormGroup>
    </FormControl>
  )
}
