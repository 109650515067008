import React from 'react';
import { CardContent, TextField, Button, Grid, Switch, FormControlLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PeopleInput from '../InputsCommt/PeopleInput';
import { 
  CREATE_CERT_TYPE, 
  UPDATE_CERT_TYPE, 
  CERT_TYPES,
  UPDATE_TEMPLATE_VAR_FOR_CT
} from '../../constants/gqls/certificates';
import TemplateVariables from '../TemplateVars/TemplateVariables';
import FileUpload from '../InputsCommt/FileUpload';
import ViewTemplateBtn from './ViewTemplateBtn';
import DeleteCertTemplateBtn from './DeleteCertTemplateBtn';
import HtmlEditor from "../ContentEditor/HtmlEditor";

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  div: {
    paddingTop: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateOrEdit({certType, setMode, then, isAdmin}) {
  const classes = useStyles();
  const [name, setName] = React.useState(certType?.name || '');
  const [description, setDescription] = React.useState(certType?.description || '');
  const [publish, setPublish] = React.useState(certType?.publish || false);
  const [managers, setManagers] = React.useState(certType?.managers || []);
  const [title, setTitle] = React.useState(certType?.requestFormTitle || '');
  const [formDescription, setFormDescription] = React.useState(certType?.requestFormDescription || '');
  const [changed, setChanged] = React.useState(false);
  const [createCertType, { loading: createLoading }] = useMutation(CREATE_CERT_TYPE, {
    refetchQueries: [ CERT_TYPES ],
  });
  const [updateCertType, { loading: updateLoading }] = useMutation(UPDATE_CERT_TYPE);
  const [createOrDeleteVar, { loading: varLoading }] = useMutation(UPDATE_TEMPLATE_VAR_FOR_CT);
  // console.log(service);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
    setChanged(true);
  }
  
  const onDescriptionChange = (value) => {
    if (value !== description) {
      setDescription(value);
      setChanged(true);
    }
  }

  const onPublishChange = (event) => {
    setPublish(event.target.checked);
    setChanged(true);
  }

  const onManagersChange = (newValue) => {
    setManagers(newValue);
    setChanged(true);
  }

  const onTitleChange = (event) => {
    setTitle(event.target.value);
    setChanged(true);
  }

  const onFormDescriptionChange = (value) => {
    if (value !== formDescription) {
      setFormDescription(value);
      setChanged(true);
    }
  }

  const addVar = () => {
    createOrDeleteVar({ variables: {
      where: { _id: certType._id },
      create: {
        variables: [{ node: {
          valueType: 'data'
        }}]
      }
    }})
  }

  const deleteVar = (varId) => {
    createOrDeleteVar({ variables: {
      where: { _id: certType._id },
      delete: {
        variables: [{ where: { node: {
          _id: varId
        }}}]
      }
    }})
  }

  const save = () => {
    if (changed && name.trim() !== '') {
      if (certType) {
        // update
        updateCertType({ variables: {
          certTypeId: certType._id,
          update: {
            name: name.trim(),
            publish, description,
            requestFormTitle: title.trim(),
            requestFormDescription: formDescription,
            managers: [{ connect: [{
              where: { node: {
                _id_IN: managers.map(m=>m._id)
              }}
            }], disconnect: [{
              where: { node: {
                _id_NOT_IN: managers.map(m=>m._id)
              }}
            }]}]
          }
        }}).then(res=>{
          console.log(res);
          setMode('view');
        });
      } else {
        createCertType({ variables: { input: {
          name: name.trim(),
          description,
          managers: { connect: [{
            where: { node: {
              _id_IN: managers.map(m=>m._id)
            }}
          }]}
        }}}).then(res=>{
          setName('');
          setManagers([]);
          setChanged(false);
          then();
        });
      }
    } else if (!changed && setMode) {
      setMode('view');
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
      <div className={classes.div}>
        <TextField fullWidth id="cert-type-name" label="Certificate name"
          margin="dense" variant="outlined"
          value={name} onChange={onNameChange}
          disabled={createLoading || updateLoading} />
      </div>
      <div className={classes.div}>
        <PeopleInput label="Managers" multiple={true}
          value={managers} onChange={onManagersChange}
          disabled={createLoading || updateLoading || !isAdmin} />
      </div>
      { certType && 
        <>
          <div className={classes.div}>
            <HtmlEditor placeholder="Description"
              html={certType.description} 
              onChange={onDescriptionChange}
              disabled={createLoading || updateLoading} />
          </div>
          <div className={classes.div}>
            <FormControlLabel label="Publish" control={
              <Switch checked={publish}
                onChange={onPublishChange} />
            } disabled={createLoading || updateLoading} />
          </div>
          <div className={classes.div}>
            <TemplateVariables variables={certType.variables}
              addVar={addVar} deleteVar={deleteVar}
              loading={varLoading} />
          </div>
          <div className={classes.div}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <p>Template:</p>
              </Grid>
              <Grid item>
                <FileUpload hasTemplate ={certType.hasTemplate}
                  systemFileType="certTemplate"
                  relatedId={certType._id}
                  fileTypes={["text/html"]}>
                    <ViewTemplateBtn certTypeId={certType._id} />
                </FileUpload>
              </Grid>
              { certType.hasTemplate && 
                <Grid item>
                  <DeleteCertTemplateBtn certTypeId={certType._id} />
                </Grid>
              }
            </Grid>
          </div>
          <div className={classes.div}>
            <TextField fullWidth id="cert-type-tile" label="Request form title"
              margin="dense" variant="outlined"
              value={title} onChange={onTitleChange}
              disabled={createLoading || updateLoading} />
          </div>
          <div className={classes.div}>
            <HtmlEditor placeholder="Request form description"
              html={certType.requestFormDescription} 
              onChange={onFormDescriptionChange}
              disabled={createLoading || updateLoading} />
          </div>
        </>
      }
      <div className={classes.div}>
        <Button variant="contained" onClick={save} disabled={createLoading || updateLoading || !changed}>
          Save
        </Button>
      </div>
    </CardContent>
  );
}
