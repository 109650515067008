import React from 'react';
import { Grid, FormControlLabel, Switch } from '@mui/material';
import { useMutation } from "@apollo/client";
import ValueFieldText from './ValueFieldText';
import FuntionBtn from './KeyFnBtn';
import { DELETE_VALUE_FIELD, UPDATE_VALUE_FIELD } from '../../../constants/gqls/dataValues';

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueField({valueField, count, inputId}) {
  const [deleteKey, { loading:deleteLoading }] = useMutation(DELETE_VALUE_FIELD);
  const [updateKey, { loading:updateLoading }] = useMutation(UPDATE_VALUE_FIELD);
  const [loading, setLoading] = React.useState(deleteLoading || updateLoading);

  React.useEffect(() => {
    setLoading(deleteLoading || updateLoading);
  }, [deleteLoading, updateLoading]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDelete = () => {
    deleteKey({ variables: {
      valueFieldId: valueField._id
    }}).then(res=>{
      // console.log(res);
    });
  }

  const handleUpdate = (update) => {
    return updateKey({ variables: {
      where: { _id: valueField._id },
      update
    }});
  }

  const updateReqired = (event) => {
    handleUpdate({
      required: event.target.checked
    }).then(res=>{
      console.log(res);
    });
  }

  const updateMultiLine = (event) => {
    handleUpdate({
      multiline: event.target.checked
    }).then(res=>{
      console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container alignItems="center">
      <Grid item xs={5} sx={{padding: 1}}>
        <ValueFieldText text={valueField.name} update={handleUpdate} />
        {/* <TextField label="Field Name" variant="outlined" fullWidth
          value={valueField.key} /> */}
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel disabled={loading}
          control={
            <Switch checked={valueField.required} 
              onChange={updateReqired} />
          }
          label="Required" labelPlacement="start"
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel disabled={loading}
          control={
            <Switch checked={valueField.multiline} 
              onChange={updateMultiLine} />
          }
          label="Multiline" labelPlacement="start"
        />
      </Grid>
      <Grid item xs={1}>
        <FuntionBtn valueField={valueField} count={count}
          handleDelete={handleDelete}
          inputId={inputId}
          loading={loading} />
        {/* <IconButton onClick={handleDelete} disabled={loading}>
          <ClearIcon />
        </IconButton> */}
      </Grid>
    </Grid>
  )
}
