import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import ServiceSessionBtn from "./ServiceSessionBtn";
import { dataMap, formSubmitted, formFilledCheck, formFilledOK } from '../../constants/cache';
import { SUBMISSION } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function SubmissionQuery({submissionId, myLastSub}) {
  const [, setSearchParams] = useSearchParams();
  const checkList = useReactiveVar(formFilledCheck);
  const { data } = useQuery(SUBMISSION, { variables: { submissionId }});
  // console.log(data);

  React.useEffect(() => {
    // console.log(data);
    if (data) {
      if (data.submission) {
        let options = [];
        formSubmitted(data.submission.submitted);
        let map = new Map();
        for (var i = 0; i < data.submission.data.length; i++) {
          let dataItem = data.submission.data[i];
          // dataItem.inputs.forEach(input => {
          //   map.set(input._id, dataItem);
          //   if (input.isDataFilter) {
          //     let filterData = map.get('data-filter') || [];
          //     filterData.push(dataItem)
          //     map.set('data-filter', filterData);
          //   }
          // });
          map.set(dataItem.input?._id || 'deleted', dataItem);
          if (dataItem.input?.isDataFilter) {
            let filterData = map.get('data-filter') || [];
            filterData.push(dataItem)
            map.set('data-filter', filterData);
          }
          if (dataItem.input?.type === 'weeklyHour') {
            let whData = map.get('weeklyHour') || [];
            whData.push(dataItem)
            map.set('weeklyHour', whData);
          }
          options = [...options, ...dataItem.options.map(opt=>opt._id)];
        }
        map.set('options', options);
        dataMap(map);
      } else if (myLastSub) {
        setSearchParams({ submission: myLastSub }, { replace: true });
      } else {
        setSearchParams({}, { replace: true });
        dataMap(null);
      }
    }
    // console.log(dataMap());
  }, [data, setSearchParams, myLastSub]);

  React.useEffect(() => {
    let checked = true;
    for (const property in checkList) {
      if (!checkList[property]) {
        checked = false;
      }
    }
    formFilledOK(checked);
  }, [checkList]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (data?.submission?.serviceSessionId) {
    return <ServiceSessionBtn serviceSessionId={data.submission.serviceSessionId} />
  }
  return <div />;
}
