import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function FormIsProfileForm({value, onChange, loading}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControlLabel labelPlacement="start" disabled={loading}
      control={<Switch checked={value} onChange={handleChange} />}
      label="Is this a profile form?"
    />
  );
}
