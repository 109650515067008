import { gql } from '@apollo/client';
import { inputDetails } from './fragments';


export const PROFILE_KEYS_SEARCH = gql`
  query ProfileKeys($where: ProfileKeyWhere, $options: ProfileKeyOptions) {
    profileKeys(where: $where, options: $options) {
      _id key
    }
  }
`;

// export const PROFILE_KEY_VALUE = gql`
//   query ProfileKeys($where: ProfileKeyWhere) {
//     profileKeys(where: $where) {
//       _id key myData {
//         _id value values
//       }
//     }
//   }
// `;

export const SET_PROFILE_KEY = gql`
  mutation SetProfileKey($itemId: ID!, $key: String!) {
    setProfileKey(itemId: $itemId, key: $key) {
      _id input { ${inputDetails} }
    }
  }
`;
