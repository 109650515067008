import React from 'react';
import { Container, Paper, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ImportTab from '../components/ProfileData/ImportTab';
import ReportTap from '../components/ProfileData/ReportTap';
import VStoStringTap from '../components/ProfileData/VStoStringTap';

const tabs = [{
  label: 'Profile Report',
}, {
  label: 'Format Output Text'
}, {
  label: 'Import'
}]

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AdminProfiles() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          { tabs.map((tab, i)=>(
            <Tab key={i} label={tab.label}/>
          ))}
        </Tabs>

        { tabs[value].label === 'Profile Report' &&
          <ReportTap />
        }
        { tabs[value].label === 'Format Output Text' &&
          <VStoStringTap />
        }
        { tabs[value].label === 'Import' &&
          <ImportTab />
        }
        
      </Paper>
    </Container>
  );
}
