import React from 'react';
// import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: '0 12px',
    padding: '48px',
    color: 'rgba(0, 0, 0, 0.5)',
    backgroundColor: '#e0e0e0',
    border: (props) => props.isActive ? '2px dashed #3b5693' : '2px dashed #aaa'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
const FileDropBox = ({onDrop, loading}) => {
  const [isActive, setIsActive] = React.useState(false);
  const classes = useStyles({isActive});
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item, monitor) => {
      if (onDrop) {
        onDrop(item, monitor)
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => (!loading)
  });
  // const isActive = canDrop && isOver;

  React.useEffect(() => {
    setIsActive(canDrop && isOver)
  }, [canDrop, isOver]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div ref={drop} className={classes.root}>
      {loading? 'Uploading...' : (isActive? 'Release to drop' : 'Drag csv file here')}
    </div>
  );
}

export default FileDropBox;
