import React from 'react';
import { CardContent, TextField, Button, Grid } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PeopleInput from '../InputsCommt/PeopleInput';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import ParticipentsInput from "../InputsCommt/ParticipentsInput";
import { CREATE_SERVICE, UPDATE_SERVICE, SERVICES, SERVICE } from '../../constants/gqls/services';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   btns: {
//
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateOrEdit({service, setMode, then, mode, isAdmin}) {
  // const classes = useStyles();
  const [name, setName] = React.useState(service?.name || '');
  const [key, setKey] = React.useState(service?.key || '');
  const [defaultHours, setDefaultHours] = React.useState(service?.defaultHours || null);
  const [providerRoles, setproviderRoles] = React.useState(service?.providerRoles || []);
  const [clientRoles, setClientRoles] = React.useState(service?.clientRoles || []);
  const [managers, setManagers] = React.useState(service?.managers || []);
  const [description, setDescription] = React.useState(service?.description || '');
  const [changed, setChanged] = React.useState(false);
  const [createService, { loading: createLoading }] = useMutation(CREATE_SERVICE, {
    refetchQueries: [ SERVICES ],
  });
  const [updateServices, { loading: updateLoading }] = useMutation(UPDATE_SERVICE, {
    refetchQueries: [ SERVICE ],
  });
  // console.log(service);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
    setChanged(true);
  }

  const onKeyChange = (event) => {
    setKey(event.target.value);
    setChanged(true);
  }

  const onHoursChange = (event) => {
    let value = parseInt(event.target.value);
    if (value) {
      setDefaultHours(value);
    } else {
      setDefaultHours(null);
    }
    setChanged(true);
  }

  const onProvRolesChange = (newValue) => {
    setproviderRoles(newValue);
    setChanged(true);
  }

  const onClientRolesChange = (newValue) => {
    setClientRoles(newValue);
    setChanged(true);
  }

  const onManagersChange = (newValue) => {
    setManagers(newValue);
    setChanged(true);
  }

  const onDescriptionChange = (value) => {
    if (value !== description) {
      setDescription(value);
      setChanged(true);
    }
  }

  const save = () => {
    if (changed && name.trim() !== '') {
      if (service) {
        // update
        updateServices({ variables: {
          id: service._id,
          update: {
            name: name.trim(),
            key: key.trim(),
            defaultHours,
            providerRoles,
            clientRoles,
            description: description.trim(),
            managers: [{ connect: [{
              where: { node: {
                _id_IN: managers.map(m=>m._id)
              }}
            }], disconnect: [{
              where: { node: {
                _id_NOT_IN: managers.map(m=>m._id)
              }}
            }]}]
          }
        }}).then(res=>{
          console.log(res);
          setMode('view');
        });
      } else {
        createService({ variables: { input: {
          name: name.trim(),
          key: key.trim(),
          defaultHours,
          providerRoles,
          clientRoles,
          description: description.trim(),
          managers: { connect: [{
            where: { node: {
              _id_IN: managers.map(m=>m._id)
            }}
          }]}
        }}}).then(res=>{
          setName('');
          setDescription('');
          setDefaultHours(null);
          setManagers([]);
          setChanged(false);
          then();
        });
      }
    } else if (!changed && setMode) {
      setMode('view');
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
      { mode !== 'view' &&
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <TextField fullWidth id="service-name" label="Service name"
              margin="dense" variant="outlined"
              value={name} onChange={onNameChange}
              disabled={createLoading || updateLoading} />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <TextField fullWidth id="service-key" label="Service key"
              margin="dense" variant="outlined"
              value={key} onChange={onKeyChange}
              disabled={createLoading || updateLoading || !isAdmin} />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <TextField fullWidth id="service-default-hours" label="Service hours"
              margin="dense" variant="outlined" type='number'
              value={defaultHours} onChange={onHoursChange}
              disabled={createLoading || updateLoading || !isAdmin} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ParticipentsInput multiple={true} label="Provider roles"
              value={providerRoles} onChange={onProvRolesChange}
              disabled={createLoading || updateLoading || !isAdmin}  />
          </Grid>
          <Grid item xs={12} md={6}>
            <ParticipentsInput multiple={true} label="Client roles"
              value={clientRoles} onChange={onClientRolesChange}
              disabled={createLoading || updateLoading || !isAdmin}  />
          </Grid>
          <Grid item xs={12}>
            <PeopleInput label="Managers" multiple={true}
              value={managers} onChange={onManagersChange}
              disabled={createLoading || updateLoading || !isAdmin} />
          </Grid>
          <Grid item xs={12}>
            <p>Description:</p>
          </Grid>
        </Grid>
      }
      <HtmlEditor html={service?.description || ''}
        onChange={onDescriptionChange} disabled={createLoading || updateLoading}
        placeholder="Service description"
        readOnly={mode === 'view'} />
      { mode !== 'view' &&
        <div>
          <Button variant="contained" onClick={save} disabled={createLoading || updateLoading}>
            Save
          </Button>
        </div>
      }
    </CardContent>
  );
}
