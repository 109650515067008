import React from 'react';
import { gql } from '@apollo/client';
import { Button, Dialog, DialogTitle, DialogContent, LinearProgress, List, ListItem } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from "@apollo/client";

const QUERY_USER = gql`
  query People($where: PersonWhere) {
    people(where: $where) {
      _id spaces { _id name }
    }
  }
`;

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     '& .MuiButtonBase-root':{
//       backgroundColor: theme.palette.secondary.main,
//       margin: 0
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
/**
 * @typedef {Object} SpacesCheckBtnProps
 * @property {string} userId - user ID.
 */

/**
 * unique key input component.
 * @param {SpacesCheckBtnProps} props - propertis
 */
function SpacesCheckBtn({ userId }) {
  const [open, setOpen] = React.useState(false);
  const [spaces, setSpaces] = React.useState([]);
  const [queryUser, {data, loading, error}] = useLazyQuery(QUERY_USER, { 
    variables: { where: { _id: userId } },
    fetchPolicy: 'network-only',
  });
  console.log(data);

  React.useEffect(() => {
    if (data && data.people[0]) {
      setSpaces(data.people[0].spaces);
    } else {
      setSpaces([])
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
    queryUser();
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button color="secondary" 
        startIcon={<RocketLaunchIcon />} 
        onClick={handleOpen}>
        Check spaces
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          User Spaces:
        </DialogTitle>
        <DialogContent>
          {loading? 
            <LinearProgress />
          : data?
            spaces.length > 0?
              <List>
                { spaces.map(space=>
                  <ListItem>{space.name}</ListItem>
                )}
              </List>
              : 'No spaces for this user'
          : 'Network error'
          }
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default SpacesCheckBtn;
