import React from 'react';
import ServiceSessionWhereEdit from './ServiceSessionWhereEdit';

//******************************************************************************
// component definition
//******************************************************************************
export default function TableWhereEdit({queryType, where, setWhere, setChanged, disabled}) {

  switch (queryType) {
    case 'serviceSessions':
      return <ServiceSessionWhereEdit
                where={where} setWhere={setWhere}
                setChanged={setChanged}
                disabled={disabled} />
    default:
      return '';
  }
}
