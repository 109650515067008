import { gql } from '@apollo/client';
import { personDetails, personSum, tagSum, boardDetails, spaceSum } from './fragments';


// export const LOGIN = gql`
//   mutation Login($code: String!) {
//     signIn(code: $code) {
//       token exp hasRefreshToken
//     }
//   }
// `;

export const LOGIN = gql`
  mutation Login($code: String!) {
    signIn(code: $code) {
      token exp
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      ${personSum}
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      token exp
    }
  }
`;

export const PERSON_BOARDS = gql`
  query PersonBoards($personId: ID, $options: CardOptions) {
    person(id: $personId) {
      ${personDetails}
      tags { ${tagSum} } ${boardDetails}
    }
  }
`;

export const PEOPLE = gql`
  query People($where: PersonWhere, $options: PersonOptions) {
    people(where: $where, options: $options) {
      ${personDetails}
    }
    peopleCount(where: $where)
  }
`;

export const SEARCH_PEOPLE = gql`
  query People($where: PersonWhere, $options: PersonOptions) {
    people(where: $where, options: $options) {
      ${personSum}
    }
  }
`;

export const PEOPLE_OR_SPACE = gql`
  query PeopleOrSpaces($search: String, $excludes: [ID]) {
    peopleOrSpaces(search: $search, excludes: $excludes) {
      ... on Person { ${personSum} nodeType }
      ... on Space { ${spaceSum} nodeType }
    }
  }
`;

// export const TAG_PERSON = gql`
//   mutation TagPerson($where: PersonWhere, $connect: PersonConnectInput, $disconnect: PersonDisconnectInput) {
//     updatePeople(where: $where, connect: $connect, disconnect: $disconnect) {
//       people {
//         _id tags { ${tagSum} }
//       }
//     }
//   }
// `;

export const UPDATE_PEOPLE = gql`
  mutation UpdatePeople($where: PersonWhere, $update: PersonUpdateInput) {
    updatePeople(where: $where, update: $update) {
      people {
        _id roles title imageUrl office phone position introduction
      }
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($id: ID!, $active: Boolean!) {
    activateUser(id: $id, active: $active) {
      _id active request roles
    }
  }
`;

export const RESET_ISUGC = gql`
  mutation Mutation {
    resetIsUGC
  }
`;

export const COUNT_ISUGC = gql`
  query Query {
    countIsUGC
  }
`;

export const REQUEST_USER_IMPORT = gql`
  mutation RequestUserImport {
    requestUserImport
  }
`;

export const COUNT_PASSIVE_USERS = gql`
  query Query {
    countPassiveUsers
  }
`;

export const CLEAR_PASSIVE_USERS = gql`
  mutation Mutation {
    clearPassiveUsers
  }
`;

export const TEST_LOGIN = gql`
  mutation TestLogin($email: String!) {
    testLogin(email: $email) {
      token exp
    }
  }
`;

export const SUB_ADMIN_NOTICE = gql`
  subscription AdminNotice {
    adminNotice {
      message finished total error
    }
  }
`;

export const TEST_SUB = gql`
  subscription TestSub($value: Int) {
    numberIncremented (value: $value)
  }
`;
