import React from 'react';
import { Collapse, Grid, List } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/client";
import ItemTextEdit from '../Items/ItemTextEdit';
import InputTypeSetting from './InputTypeSetting';
import WeeklyHourSetting from './WeeklyHourSetting';
import InputImportFormBtn from './InputImportFormBtn';
import InputProfileKeyBtn from './InputProfileKeyBtn';
import InputValueSettingBtn from './ValueSets/InputValueSettingBtn';
import InputDataFilterSetting from './InputDataFilterSetting';
import InputRequiredSetting from './InputRequiredSetting';
import InputOtherSetting from './InputOtherSetting';
import InputOptionItem from './InputOptionItem';
import InputOptionOtherItem from './InputOptionOtherItem';
import InputOptionAdd from './InputOptionAdd';
import { UPDATE_INPUT, SORT_CHOICES } from '../../constants/gqls/items';
import { DOCUMENT } from '../../constants/gqls/documents';
import { ME } from '../../constants/gqls/user';


function itemIcon(type) {
  return {
    radio: <RadioButtonUncheckedIcon />,
    checkbox: <CheckBoxOutlineBlankIcon />,
    dropdown: <ArrowRightIcon />,
  }[type]
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '640px',
      width: '640px',
    }
  },
  row: {
    marginTop: theme.spacing(1),
  },
  inputType: {
    '&.MuiFormControl-root': {
      width: '180px'
    }
  },
  typeOption: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputItemSetting({item, updateText, loading, setFocus}) {
  const classes = useStyles();
  const { data } = useQuery(ME);
  const [updateInputMutation, { loading:inputLoading }] = useMutation(UPDATE_INPUT);
  const [updateInputRefetch] = useMutation(UPDATE_INPUT, {
    refetchQueries: [ DOCUMENT ],
  });
  const [sortOptionsMutation] = useMutation(SORT_CHOICES);
  const options = item.input? [...item.input.optionsConnection.edges] : [];
  options.sort((a,b)=>a.index-b.index);
  const inputSettingDisabled = (item.type === 'filter');
  // console.log(item.input);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const updateInput = (update) => {
    return updateInputMutation({ variables: {
      inputId: item.input._id,
      update
    }});
  }

  const updateInputType = (update) => {
    return updateInputRefetch({ variables: {
      inputId: item.input._id,
      update
    }});
  }

  const addOption = (text) => {
    return updateInput({ options: [{ create: [{
      edge: { index: options.length },
      node: { text }
    }]}]});
  }

  const sortOptions = (optionId, index) => {
    sortOptionsMutation({ variables: {
      inputId: item.input._id,
      optionId, index
    }}).then(res=>{
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!item.input) {
    return <div className={classes.root}>Error: input not found!</div>
  };
  
  return (
    <React.Fragment>
      <ItemTextEdit itemText={item.text} loading={loading} editItem={updateText} setFocus={setFocus} />
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputTypeSetting loading={inputLoading || inputSettingDisabled}
              value={item.input.type}
              importFormId={item.input.importFormId}
              service={item.input.service}
              updateInput={updateInputType} setFocus={setFocus} />
          </Grid>
          <Grid item xs={12} md={3}>
            { item.input.service &&
              <InputImportFormBtn loading={inputLoading ||inputSettingDisabled}
                importFormId={item.input.importFormId}
                service={item.input.service}
                importType={item.input.type}
                updateInput={updateInput}
                importInputs={item.input.applicationFilterInputs?.map(inp=>inp._id)} />
            }
            { ['radio', 'dropdown'].includes(item.input.type) &&
              <InputDataFilterSetting value={item.input.isDataFilter}
                loading={inputLoading || inputSettingDisabled}
                updateInput={updateInput} setFocus={setFocus}  />
            }
            { item.input.type === 'valueSets' &&
              <InputValueSettingBtn inputId={item.input._id}
                valueFields={item.input.valueFields} />
            }
          </Grid>
          {/*<Grid item xs={12} md>
            <InputKeySetting value={item.input.key} loading={inputLoading}
              updateInput={updateInput} setFocus={setFocus}  />
          </Grid>*/}
          <Grid item xs={12} md="auto">
            <InputRequiredSetting value={item.input.required}
              loading={inputLoading || inputSettingDisabled}
              updateInput={updateInput} setFocus={setFocus}  />
          </Grid>
          { data.me.roles.includes('admin') && 
            !item.input.isDataFilter &&
            item.input.type !== 'tutoringHour' &&
            <Grid item xs={12} md="auto">
              <InputProfileKeyBtn itemId={item._id}
                profileKey={item.input.profileKey}  />
            </Grid>
          }
        </Grid>
      </div>
      <Collapse timeout="auto" unmountOnExit
        in={["radio", "checkbox", "dropdown"].includes(item.input.type)}>
        <List>
          { options.map(option=>(
            <InputOptionItem key={option.node._id}
              icon={itemIcon(item.input.type)}
              option={option} setFocus={setFocus} count={options.length}
              updateInput={updateInput} sortOptions={sortOptions}
              loading={inputLoading || inputSettingDisabled} />
          ))}
          { item.input.other &&
            <InputOptionOtherItem icon={itemIcon(item.input.type)} />
          }
        </List>
        <div className={classes.row}>
          <Grid container spacing={1}>
            <Grid item xs>
              <InputOptionAdd addOption={addOption} setFocus={setFocus}
                loading={inputLoading || inputSettingDisabled} />
            </Grid>
            { ["radio", "checkbox"].includes(item.input.type) &&
              <Grid item xs={12} md="auto">
                <InputOtherSetting value={item.input.other}
                  loading={inputLoading || inputSettingDisabled}
                  updateInput={updateInput} setFocus={setFocus}  />
              </Grid>
            }
          </Grid>
        </div>
      </Collapse>
      <Collapse timeout="auto" unmountOnExit
        in={item.input.type === 'weeklyHour' || item.input.type === 'weeklyHourMulti'}
      >
        <WeeklyHourSetting loading={inputLoading} updateInput={updateInput}
          weeklyDayOptions={item.input.weeklyDayOptions}
          weeklyHourOptions={item.input.weeklyHourOptions} />
      </Collapse>
    </React.Fragment>
  )
}
