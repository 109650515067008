export const isValidUrl = (url) => {
  let urlRegx = /^(https?:\/\/)([\dA-Za-z.-]+)\.([a-z.]{2,6})(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
  return urlRegx.test(url);
};

export const DateTimeToString = (str) => {
  let d = new Date(str);
  return d.toLocaleDateString() +' '+ d.toLocaleTimeString()
};

export const DateToString = (str) => {
  let d = new Date(str);
  return d.toLocaleDateString()
};

const valueToString = (value) => {
  let trimed = value.text?.trim() || '';
  if (trimed === '' || !value.field.toStr) {
    return trimed;
  } else {
    let regx = new RegExp(`\\[\\[text\\]\\]`, 'g');
    return value.field.toStr.replace(regx, trimed);
  }
}

export const valueSetToString = (valueSet) => {
  let values = [...valueSet.valueList];
  let vStr = values.sort((a,b)=>(
      a.field.index-b.field.index
    )).map(v=>valueToString(v)).filter(v=>v!=='').join(' ');
  return vStr;
};

export const dataToString = (data, inSeparator) => {
  let textValue = '';
  let separator = inSeparator || ', ';
  if (data) {
    if (data.options.length !== 0) {
      textValue = data.options.map(opt=>opt.text).join(separator);
    } else if (data.values?.length) {
      textValue = data.values.join(separator);
    } else if (data.value) {
      textValue = data.value;
    } else if(typeof data.boolValue === 'boolean') {
      textValue = data.boolValue? 'Yes' : 'No'
    }
  }
  return textValue;
};

export const extractHtmlContent = (html) => {
  return new DOMParser()
      .parseFromString(html, "text/html")
      .documentElement.textContent;
}


export const scopeSelects = ['system', 'personal'];
export const emailTemplatesFixed = ['Welcome'];
