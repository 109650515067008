import { gql } from '@apollo/client';
import { tagTypeSum, personSum, tagSum, itemDetails } from './fragments';
const getTags = `tags(where: $where) { ${tagSum} }`;

export const TAGS = gql`
  query Tags($where: TagWhere, $options: TagOptions) {
    tags(where: $where, options: $options) {
      ${tagSum} taggedCount
    }
    tagsCount(where: $where)
  }
`;

export const TAGS_FILTER = gql`
  query TagsFilter($where: TagWhere, $tags: [ID]) {
    ${getTags}
    relatedTags(tags: $tags) { ${tagSum} }
  }
`;

export const TAGGED_OBJECTS = gql`
  query TaggedObjects($tags: [ID], $offset: Int, $limit: Int) {
    taggedObjects(tags: $tags, offset: $offset, limit: $limit) {
      ... on Document { _id title description nodeType tags { ${tagSum} }}
      ... on Item { ${itemDetails} nodeType ownerDoc { _id }}
      ... on Space { _id nodeType name description tags { ${tagSum} }}
      ... on Person { ${personSum} nodeType tags { ${tagSum} }}
      ... on Data { _id nodeType value tags { ${tagSum} }}
    }
    taggedObjectsCount(tags: $tags)
  }
`;

export const MY_TAGGED_TAGS = gql`
  query MyTaggedTags($offset: Int!, $limit: Int!) {
    myTaggedTags(offset: $offset, limit: $limit) {
      ${tagSum} clickedCount
    }
    myTaggedTagsCount
  }
`;

// export const GET_TAGS = gql`
//   query GetTags($where: TagWhere) {
//     ${getTags}
//   }
// `;

export const TAGS_FOR_ADD = gql`
  query TagsForAdd($excludes: [ID], $search: String) {
    tagsForAdd(excludes: $excludes, search: $search) {
      ${tagSum}
    }
  }
`;

export const MY_TAGS = gql`
  query MyTags($search: String, $scope: [String]!, $offset: Int, $limit: Int) {
    myTags(search: $search, scope: $scope, offset: $offset, limit: $limit) {
      ${tagSum} myTaggedCount
    }
    myTagsCount(search: $search, scope: $scope)
  }
`;

export const TAG_DETAILS = gql`
  query TagDetails($where: TagWhere) {
    tags(where: $where) {
      ${tagSum} taggedCount scope description createdAt updatedAt
      createdBy {
        ${personSum}
      }
    }
  }
`;

export const CLICK_TAG = gql`
  mutation ClickTag($tagId: ID!) {
    clickTag(id: $tagId) {
      tags {
        _id clickedCount
      }
    }
  }
`;

export const TAG_OBJECT = gql`
  mutation TagObject($tagId: ID!, $objId: ID!) {
    tagObject(tagId: $tagId, objId: $objId) {
      ... on Document {
        _id nodeType tags { ${tagSum} }
      }
      ... on Item {
        _id nodeType tags { ${tagSum} }
      }
      ... on Person {
        _id nodeType tags { ${tagSum} }
      }
      ... on  Space {
        _id nodeType tags { ${tagSum} }
      }
    }
  }
`;

export const DETAG_OBJECT = gql`
  mutation DeTagObject($tagId: ID!, $objId: ID!) {
    deTagObject(tagId: $tagId, objId: $objId) {
      ... on Document {
        _id nodeType tags { ${tagSum} }
      }
      ... on Item {
        _id nodeType tags { ${tagSum} }
      }
      ... on Person {
        _id nodeType tags { ${tagSum} }
      }
      ... on  Space {
        _id nodeType tags { ${tagSum} }
      }
    }
  }
`;

// export const TAGGED_OBJECTS = gql`
//   query TaggedObjects($tags: [ID]) {
//     taggedObjects(tags: $tags) {
//       ... on Document {
//         _id nodeType
//       }
//       ... on Item {
//         _id nodeType
//       }
//     }
//   }
// `;

export const CREATE_SYS_TAG = gql`
  mutation CreateSystemTag($key: String, $description: String, $type: ID, $scope: [String]) {
    createSystemTag(key: $key, description: $description, type: $type, scope: $scope) {
      ${tagSum}
    }
  }
`;

export const CREATE_PERSONAL_TAG = gql`
  mutation CreatePersonalTag($key: String, $description: String) {
    createPersonalTag(key: $key, description: $description) {
      ${tagSum}
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($where: TagWhere, $update: TagUpdateInput) {
    updateTags(where: $where, update: $update) {
      tags {
        ${tagSum} scope description updatedAt
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($where: TagWhere) {
    deleteTags(where: $where) {
      nodesDeleted relationshipsDeleted
    }
  }
`;

export const TAG_TYPES = gql`
  query TagTypes($options: Tag_typeOptions) {
    tagTypes(options: $options) {
      ${tagTypeSum}
    }
    tagTypesCount
  }
`;

export const TAG_TYPES_SEARCH = gql`
  query TagTypeSearch($nameSearch: String) {
    tagTypeSearch(nameSearch: $nameSearch) {
      ${tagTypeSum}
    }
  }
`;

export const TAG_TYPE_DETAILS = gql`
  query TagTypeDetails($where: Tag_typeWhere, $options: TagOptions) {
    tagTypes(where: $where) {
      _id name bgColor textColor description createdAt updatedAt
      createdBy {
        ${personSum}
      }
      tags(options: $options) {
        _id key
      }
    }
  }
`;

export const CREATE_TAG_TYPE = gql`
  mutation CreateTagType($input: [Tag_typeCreateInput!]!) {
    createTag_types(input: $input) {
      tagTypes {
        ${tagTypeSum}
      }
    }
  }
`;

export const UPDATE_TAG_TYPE = gql`
  mutation UpdateTagType($where: Tag_typeWhere, $update: Tag_typeUpdateInput) {
    updateTag_types(where: $where, update: $update) {
      tagTypes {
        _id name bgColor textColor description updatedAt
      }
    }
  }
`;

export const DELETE_TAG_TYPE = gql`
  mutation DeleteTagType($where: Tag_typeWhere) {
    deleteTag_types(where: $where) {
      relationshipsDeleted nodesDeleted
    }
  }
`;
