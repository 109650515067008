import React from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import UploadBtn from './UploadBtn';
import { FILE_UPLOAD } from '../../constants/gqls/files';

const labels = {
  certTemplate: 'Upload Template'
};

//******************************************************************************
// component definition
//******************************************************************************
export default function FileUpload({ hasTemplate, systemFileType, relatedId, fileTypes, children }) {
  const [loading, setLoading] = React.useState(false);
  const [requestUpload] = useMutation(FILE_UPLOAD);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpload = (event) => {
    let theFile = event.target.files[0];

    if (fileTypes.includes(theFile.type)) {
      setLoading(true);
      requestUpload({ variables: {systemFileType, relatedId} }).then(res=>{
        let token = res.data.uploadFile;
        console.log(token);

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
          "theFile",
          theFile,
          theFile.name
        );

        // Details of the uploaded file
        // console.log(theFile);

        // Request made to the backend api
        // Send formData object
        axios.post(process.env.REACT_APP_UPLOAD_URI + token, formData).then((res)=>{
          setLoading(false);
        }).catch((error) => {
          console.log(error.response);
          setLoading(false);
        });
      });
    }
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        { hasTemplate? children : <p>none</p> }
      </Grid>
      <Grid item>
        <UploadBtn label={labels[systemFileType]} loading={loading}
  onChange={handleUpload} />
      </Grid>
    </Grid>
    
  );
}
