import React from 'react';
import clsx from 'clsx';
import { Grid, Divider, Button, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import FunctionBtns from './FunctionBtns';
import DateTimeInput from './DateTimeInput';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { DateToString } from '../../constants/utils';
import { UPDATE_ATTENDANCE } from '../../constants/gqls/attendances';
import { SERVICE_SESSION } from '../../constants/gqls/serviceSessions';
import ParticipantEdit from './ParticipantEdit';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    '& hr':{
      margin: `${theme.spacing(2)}px 0`
    }
  },
  note: {
    fontFamily: 'inherit'
  },
  labelText: {
    color: theme.palette.secondary.main
  },
  btns: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  },
  space: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  lineBreak: {
    paddingTop: theme.spacing(1)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AttendanceItem({ attendance, sParticipants, index }) {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [hours, setHours] = React.useState(attendance.hours);
  const [pList, setPList] = React.useState([]);
  const [updateAttendance, { loading }] = useMutation(UPDATE_ATTENDANCE, {
    refetchQueries: [ SERVICE_SESSION ],
  });
  // console.log(sParticipants);
  // console.log(extractContent(attendance.note));

  React.useEffect(() => {
    if (attendance.note) {
      setNote(attendance.note)
    }
    let newList = [...attendance.participants];
    newList.sort((a,b)=>a.email<b.email? 1 : -1);
    // console.log(newList);
    setPList(newList);
  }, [attendance]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const editClicked = () => {
    setEditMode(!editMode);
  }

  const handleNoteChange = (value) => {
    if (value !== note) {
      setNote(value);
    }
  }

  const handleHoursChange = (event) => {
    setHours(event.target.value)
  }

  const handleUpdate = (inUpdate) => {
    return updateAttendance({ variables: {
      id: attendance._id,
      inUpdate
    }});
  }

  const save = () => {
    handleUpdate({ note, 
      hours: parseFloat(hours) 
    });
    setEditMode(false);
  }

  const submit = () => {
    handleUpdate({ submitted: true });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      { index !== 0 && <Divider />}
      <Grid container alignItems="center" spacing={2} >
        <Grid item xs="auto" className={classes.labelText}>
          Time:
        </Grid>
        <Grid item xs>
          { editMode ?
            <DateTimeInput attendance={attendance}
              handleUpdate={handleUpdate} loading={loading} />
            : `${DateToString(attendance.date)}, ${attendance.time}`
          }
        </Grid>
        { !attendance.submitted &&
          <Grid item xs="auto">
            Drafting
          </Grid>
        }
        { attendance.deleteable &&
          <Grid item xs="auto">
            <FunctionBtns attendance={attendance} editClicked={editClicked} />
          </Grid>
        }
      </Grid>
      <Grid container alignItems="center" spacing={3} >
        <Grid item xs="auto" className={classes.labelText}>
          Hours:
        </Grid>
        <Grid item xs container alignItems="center" spacing={3}>
          { editMode ? 
            <Grid item>
              <TextField id="attendance-hours" variant="standard"
                disabled={loading}
                type="number" min="0.5" step="0.5"
                value={hours} onChange={handleHoursChange}  />
            </Grid>
            : <Grid item>{attendance.hours}</Grid>
          }
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={3} className={classes.lineBreak}>
        <Grid item xs="auto" className={classes.labelText}>
          Participants:
        </Grid>
        <Grid item xs container alignItems="center" spacing={3}>
          { editMode ? 
            <Grid item>
              <ParticipantEdit options={sParticipants} 
                participants={attendance.participants}
                update={handleUpdate} />
            </Grid>
            : pList.map(pp=>(
              <Grid item xs="auto" key={pp._id}>
                {pp.name}
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      <div className={clsx(classes.labelText, classes.space)}>
        Report:
      </div>
      <HtmlEditor placeholder="Report or remarks goes here"
        html={attendance.note} onChange={handleNoteChange}
        readOnly={!editMode} disabled={loading} />
      <div className={classes.btns}>
        { editMode?
          <>
            <Button onClick={editClicked}>
              Cancel
            </Button>
            <Button variant="contained" onClick={save} disabled={loading}>
              Save
            </Button>
          </>
          :
          !attendance.submitted &&
          <Button variant="outlined" onClick={submit} disabled={loading}>
            Submit
          </Button>
        }
      </div>
    </div>
  );
}
