import { gql } from '@apollo/client';
import { linkDetails, inputDetails } from './fragments';

export const CREATE_ITEM = gql`
  mutation CreateItem($index: Int!, $text: String!, $docId: ID!) {
    createItem(index: $index, text: $text, docId: $docId) {
      _id type text
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation UpdateItem($itemId: ID!, $text: String, $type: ItemType, $preconditions: [String]) {
    updateItem(itemId: $itemId, text: $text, type: $type, preconditions: $preconditions) {
      items { _id text type ${linkDetails} input {${inputDetails}} preconditions { _id text }}
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation DeleteItem($itemId: ID!) {
    deleteItem(itemId: $itemId)
  }
`;

export const UPDATE_INPUT = gql`
  mutation UpdateInput($inputId: ID!, $update: InputUpdateInput!) {
    updateInput(inputId: $inputId, update: $update) {
      inputs { ${inputDetails} }
    }
  }
`;

export const SORT_CHOICES = gql`
  mutation SortOptions($inputId: ID!, $optionId: ID, $index: Int) {
    sortOptions(inputId: $inputId, optionId: $optionId, index: $index) {
      inputs { _id optionsConnection { edges { index node { _id }}}}
    }
  }
`;
