import React from 'react';
import { Divider, CardContent } from '@mui/material';
import UploadInput from '../InputsCommt/UploadInput';
import TemplateGenerator from './TemplateGenerator';
import ImportArgs from './ImportArgs';
import { REQUEST_PROFILE_DATA_IMPORT } from '../../constants/gqls/dataValues';

//******************************************************************************
// component definition
//******************************************************************************
export default function ImportTab() {
  const [valueKey, setValueKey] = React.useState(null);
  const [global, setGlobal] = React.useState(false);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <CardContent>
        <ImportArgs 
          valueKey={valueKey} setValueKey={setValueKey} 
          global={global} setGlobal={setGlobal}
        />
      </CardContent>
      <UploadInput requestMutation={REQUEST_PROFILE_DATA_IMPORT}
        apiName="requestProfileDataImport"
        fileTypes={['text/csv']}
        variables={{valueKey, global}} />
      <Divider />
      <CardContent>
        <h3>
          Instructions:
        </h3>
        <p>
          Data file should be in <strong>csv</strong> format. Data columns read by the system:
        </p>
        <ul>
          <li><strong>email</strong>: This is the key. Please make sure it's correct and MUST exist.</li>
        </ul>
        <span>Except the email column, all other columns should be:</span><br />
        <ul>
          <li>either all "[profileKey]" without the "."</li>
          <li>or all "[profileKey].[valueField]"</li>
          <li>and [profileKey] in "[profileKey].[valueField]" should be same for all</li>
        </ul>
        <span>Examples:</span><br />
        <ul>
          <li>"email", "Job Title", "Staff ID", "Phone"</li>
          <li>"email", "Grants.Year", "Grants.Name", "Grants.Amount"</li>
        </ul>
        <span>Key Field:</span><br />
        <ul>
          <li>Select a value field as the "Key Field", system will look for any same value for defined value field in existing data before creating a new record.</li>
          <li>If record found, existing record will be updated.</li>
          <li>For same profile owner only.</li>
        </ul>
        <span>Global:</span><br />
        <ul>
          <li>When two or more teachers publish a paper togther, they can share a same record.</li>
          <li>Here system will look for data records belong to other profile owner also, but match only when ALL fields value are the same, not just the "Key Field".</li>
        </ul>
        <h3>
          Key points:
        </h3>
        <ol>
          <li>Strictly follow the downloaded csv template (column order doesn't matter).</li>
          <li>Use "," as the separator.</li>
          <li>To display Chinese characters correctly, save the csv file in <strong>UTF-8</strong>.</li>
          <li>Use notepad to open and check the csv file before uploading.</li>
        </ol>
        <h3>
          Generate Template:
        </h3>
        <TemplateGenerator />
      </CardContent>
    </div>
  );
}
