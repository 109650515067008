import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  fieldBox: {
    paddingBottom: theme.spacing(1)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueFieldInput({value, valueField, disabled, update}) {
  const classes = useStyles();
  const [text, setText] = React.useState(value?.text || '');
  const ref = React.useRef(null);
  const timer = React.useRef(0);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setText(event.target.value);
    clearTimeout(timer.current);
    timer.current = setTimeout(()=>{
      update(valueField._id, event.target.value).then(res=>{
        ref.current.focus();
      });
    }, 500);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.fieldBox}>
      <TextField fullWidth variant="outlined" size="small"
        inputRef={ref}
        rows={valueField.multiline? 4 : 1}
        multiline={valueField.multiline} 
        label={valueField.name+(valueField.required? ' *':'')}
        value={text} onChange={handleChange} disabled={disabled} />
    </div>
  )
}
