import React from 'react';
import { CardContent, TextField, Button, CardActions, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { CREATE_DATA_TABLE, UPDATE_DATA_TABLE, DATA_TABLES, DATA_TABLE_DETAILS } from '../../constants/gqls/dataAnalysis';
import ReportTypeSelect from './ReportTypeSelect';
import TableParmsEdit from './TableParmsEdit';
import ExportBtn from './ExportBtn';
import DeleteBtn from './DeleteBtn';


//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  btns: {
    '& .MuiButton-root': {
      margin: 'auto'
    }
  },
  paramBox: {
    '& .MuiDivider-root': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateOrEdit({dataTable, then}) {
  const classes = useStyles();
  const [name, setName] = React.useState(dataTable?.name || '');
  const [queryType, setQueryType] = React.useState(dataTable?.queryType || null);
  const [whereType, setWhereType] = React.useState(dataTable?.whereType || null);
  const [optionsType, setOptionsType] = React.useState(dataTable?.optionsType || null);
  const [where, setWhere] = React.useState(dataTable?.where || '');
  const [queryFields, setQueryFields] = React.useState(dataTable?.queryFields || '');
  const [options, setOptions] = React.useState(dataTable?.options || '');
  const [disabled, setDisabled] = React.useState(true);
  const [changed, setChanged] = React.useState(false);
  const [createDataTable, { loading: createLoading }] = useMutation(CREATE_DATA_TABLE, {
    refetchQueries: [ DATA_TABLES ],
  });
  const [updateDataTables, { loading: updateLoading }] = useMutation(UPDATE_DATA_TABLE, {
    refetchQueries: [ DATA_TABLE_DETAILS ],
  });
  // console.log(queryType);

  React.useEffect(() => {
    setDisabled(!changed || name.trim() === '' || !queryType || createLoading || updateLoading);
  }, [changed, name, queryType, createLoading, updateLoading]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
    setChanged(true);
  }

  const onReportTypeChange = (newValue) => {
    setQueryType(newValue);
    setChanged(true);
  }

  const save = () => {
    if (changed && name.trim() !== '' && queryType) {
      if (dataTable) {
        // update
        updateDataTables({ variables: {
          where: { _id: dataTable._id },
          update: { 
            name, queryType, whereType, optionsType, queryFields, where, options
          }
        }}).then(res=>{
          // console.log(res);
          setChanged(false);
          if (then) {
            then();
          }
        });
      } else {
        createDataTable({ variables: {
          input: [{
            name, queryType, whereType, optionsType
          }]
        }}).then(res=>{
          setName('');
          setQueryType('');
          setChanged(false);
          if (then) {
            then();
          }
        });
      }
    }
  }

  const addColumn = (name='Column', field='_id', code) => {
    updateDataTables({ variables: {
      where: { _id: dataTable._id },
      update: { 
        dataTableColumns: {
          create: [{ node: { name, field, code }}]
        }
      }
    }}).then(res=>{
      // console.log(res);
    });
  }

  const addColumns = () => {
    let fields = dataTable.queryFields.split('\n')[0]
                    .trim().split(' ').filter(item=>item!=='_id');
    console.log(fields);
    let create = fields.map(item=>({ node: { name:item, field:item }}));
    updateDataTables({ variables: {
      where: { _id: dataTable._id },
      update: { 
        dataTableColumns: { create }
      }
    }}).then(res=>{
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <CardContent>
        <TextField fullWidth id="data-table-name" label="Table name"
          margin="dense" variant="outlined"
          value={name} onChange={onNameChange}
          disabled={createLoading || updateLoading} />
        <ReportTypeSelect 
          value={queryType} 
          setWhereType={setWhereType}
          setOptionsType={setOptionsType}
          onChange={onReportTypeChange}
          disabled={createLoading || updateLoading} />
        { dataTable &&
          <div className={classes.paramBox}>
            <Divider>Parameters</Divider>
            <TableParmsEdit queryType={queryType}
              where={where} setWhere={setWhere}
              queryFields={queryFields} setQueryFields={setQueryFields}
              options={options} setOptions={setOptions}
              addColumn={addColumn} columns={dataTable.dataTableColumns}
              addColumns={addColumns}
              setChanged={setChanged}
              disabled={createLoading || updateLoading}/>
          </div>
        }
      </CardContent>
      <CardActions className={classes.btns}>
        <Button variant="contained" onClick={save} disabled={disabled}>
          Save
        </Button>
        { dataTable && 
          <>
            <ExportBtn dataTable={dataTable} />
            <DeleteBtn dataTableId={dataTable._id} />
          </>
        }
      </CardActions>
    </>
  );
}
